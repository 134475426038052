<template>
	<pop-up-window
		:show="this.$store.state.displayEditPayments"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit payments</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">Payment:</label>
						<ToggleSlide
							@update-value="updatePaymentBool"
							class="w-min"
							:bool="boolPayment"
						/>
					</div>
					<div v-if="payment">
						<!--Payment Provider-->
						<div class="mb-3 ml-6">
							<label class="font-bold form-label">Provider: *</label>
							<Dropdown
								:class="{ 'border border-primary-3': providerInvalid }"
								@update-value="updateProvider"
								:data="paymentProviderData"
								:selected="paymentProviderComputed"
								:key="paymentProviderComputed"
							/>
						</div>
						<span v-if="providerInvalid" class="text-primary-3 ml-6">Provider is required</span>
						<!--Pay Page Provider-->
						<div class="mb-3 ml-6">
							<label class="font-bold form-label">Paypage Provider: *</label>
							<Dropdown
								id="editPayPageProvider"
								:class="{ 'border border-primary-3': payPageInvalid }"
								@update-value="updatePayPage"
								:data="paypageProviderData"
								:selected="paymentPayPageComputed"
								:key="paymentPayPageComputed"
							/>
						</div>
						<span v-if="payPageInvalid" class="text-primary-3 ml-6">
							Paypage is required
						</span>

						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">
								Transactions via Twikey:</label>
							<ToggleSlide
								@update-value="updateTransactionsViaTwikey"
								class="w-min"
								:bool="transactionsViaTwikey"
							/>
						</div>
					</div>
				</div>
				<div class="flex flex-col w-1/2 mr-12">
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">Show Paystatus:</label>
						<ToggleSlide
							@update-value="updateShowPaystatus"
							class="w-min"
							:bool="showPaymentStatus"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">Show Mandates:</label>
						<ToggleSlide
							@update-value="updateShowMandates"
							class="w-min"
							:bool="showMandates"
						/>
					</div>
					<div class="mb-3 flex">
						<label for="ediShowCodaFiles" class="font-bold form-label mb-0 mr-2">Show Coda files:</label>
						<ToggleSlide
							@update-value="updateShowCodaFiles"
							class="w-min"
							:bool="showCodaFiles"
						/>
					</div>
				</div>
			</div>

			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import ToggleSlide from "../ToggleSlide.vue";
import Dropdown from "../Dropdown.vue";

export default {
	name: "EditPayments",
	components: {
		PopUpWindow,
		ToggleSlide,
		Dropdown
	},
	data() {
		return {
			paymentProviderData: [
				{id: 0, name: "No payment provider"},
				{id: 1, name: "TWIKEY"},
			],
			paypageProviderData: [
				{id: 0, name: "No paypage provider"},
				{id: 1, name: "TWIKEY"},
			],
			providerInvalid: false,
			payPageInvalid: false,
			payment: !!this.$store.state.activeSG.channelsConfig.payment,
			paymentStatus: this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus : false,


			mandateStatus: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig?.showMandates
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showMandates : false,

			codaFilesStatus: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig?.showCodaFiles
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showCodaFiles : false,

			transactionsViaTwikeyStatus: this.$store.state.activeSG.channelsConfig.payment?.transactionsViaTwikey
				? this.$store.state.activeSG.channelsConfig.payment.transactionsViaTwikey : false,
		}
	},
	computed: {
		transactionsViaTwikey() {
			if (this.$store.state.activeSG.channelsConfig.payment && this.$store.state.activeSG.channelsConfig.payment.transactionsViaTwikey) {
				this.setTransactionsViaTwikey(this.$store.state.activeSG.channelsConfig.payment.transactionsViaTwikey)
				return this.$store.state.activeSG.channelsConfig.payment.transactionsViaTwikey
			}
			this.setTransactionsViaTwikey(false)
			return false
		},

		showPaymentStatus() {
			if (this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus) {
				this.setPaymentStatus(this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus)
				return this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus
			}
			this.setPaymentStatus(false)
			return false
		},
		showMandates() {
			if (this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig?.showMandates) {
				this.setMandateStatus(this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showMandates)
				return this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showMandates
			}
			this.setMandateStatus(false)
			return false
		},
		showCodaFiles() {
			if (this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig?.showCodaFiles) {
				this.setCodaFilesStatus(this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showCodaFiles)
				return this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showCodaFiles
			}
			this.setCodaFilesStatus(false)
			return false
		},
		boolPayment() {
			if (this.$store.state.activeSG.channelsConfig.payment) {
				this.setPayment(true);
				return true
			}
			this.setPayment(false)
			return false
		},
		formData() {
			return {
				payment: {
					provider: this.$store.state.activeSG.channelsConfig.payment?.provider
						? this.$store.state.activeSG.channelsConfig.payment.provider : '',
					payPageProvider: this.$store.state.activeSG.channelsConfig.payment?.payPageProvider
						? this.$store.state.activeSG.channelsConfig.payment.payPageProvider : '',
					transactionsViaTwikey: this.$store.state.activeSG.channelsConfig.payment?.transactionsViaTwikey
						? this.$store.state.activeSG.channelsConfig.payment.transactionsViaTwikey : false,
				},
				showPaymentStatus: this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus : false,

				showMandates: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig?.showMandates
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showMandates : false,
				showCodaFiles: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig?.showCodaFiles
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showCodaFiles : false,
			}
		},
		paymentProviderComputed() {
			if (this.$store.state.activeSG.channelsConfig.payment) return this.formData.payment.provider
			return this.paymentProviderData[0].name
		},
		paymentPayPageComputed() {
			if (this.$store.state.activeSG.channelsConfig.payment) return this.formData.payment.payPageProvider
			return this.paypageProviderData[0].name
		},
	},
	methods: {
		setTransactionsViaTwikey(status) {
			this.transactionsViaTwikeyStatus = status
		},
		setPaymentStatus(status) {
			this.paymentStatus = status
		},
		setMandateStatus(status) {
			this.mandateStatus = status
		},
		setCodaFilesStatus(status) {
			this.codaFilesStatus = status
		},
		setPayment(payment) {
			this.payment = payment
		},
		updatePaymentBool(data) {
			this.payment = data
		},
		updateProvider(data) {
			this.providerInvalid = false
			if (!this.formData.payment) this.formData.payment = {}
			if (this.paymentProviderData[data]) this.formData.payment.provider = this.paymentProviderData[data].name
		},
		updatePayPage(data) {
			this.payPageInvalid = false;
			if (!this.formData.payment) this.formData.payment = {}
			if (this.paypageProviderData[data]) this.formData.payment.payPageProvider = this.paypageProviderData[data].name
		},
		updateShowPaystatus(data) {
			this.formData.showPaymentStatus = data
		},
		updateShowMandates(data) {
			this.formData.showMandates = data
		},
		updateShowCodaFiles(data) {
			this.formData.showCodaFiles = data
		},
		updateTransactionsViaTwikey(data) {
			this.formData.payment.transactionsViaTwikey = data
		},
		validate() {
			let hasErrors = false
			if (this.payment) {
				if (this.formData.payment.provider === "" || this.formData.payment.provider === "No payment provider") {
					this.providerInvalid = true
					hasErrors = true
				}
				if (this.formData.payment.payPageProvider === "" || this.formData.payment.payPageProvider === "No paypage provider") {
					this.payPageInvalid = true
					hasErrors = true
				}
			}
			return hasErrors
		},

		close() {
			this.payment = !!this.$store.state.activeSG.channelsConfig.payment,
				this.boolPayment = !!this.$store.state.activeSG.channelsConfig.payment,
				this.formData = {
					payment: {
						provider: this.$store.state.activeSG.channelsConfig.payment?.provider
							? this.$store.state.activeSG.channelsConfig.payment.provider : '',
						payPageProvider: this.$store.state.activeSG.channelsConfig.payment?.payPageProvider
							? this.$store.state.activeSG.channelsConfig.payment.payPageProvider : '',
						transactionsViaTwikey: this.$store.state.activeSG.channelsConfig.payment?.transactionsViaTwikey
							? this.$store.state.activeSG.channelsConfig.payment.transactionsViaTwikey : false,
					},
					showPaymentStatus: this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus
						? this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus : false,
					showMandates: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig?.showMandates
						? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showMandates : false,
					showCodaFiles: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig?.showCodaFiles
						? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showCodaFiles : false
				}
			this.$store.state.displayEditPayments = false
		},
		update() {
			let hasErrors = this.validate()
			if (hasErrors) return

			if (!this.payment) {
				if (this.formData.payment) delete this.formData.payment
			}
			let payload = {
				formData: this.formData,
				supplierGroupId: this.$store.state.activeSG.supplierGroupId
			}
			this.$store.dispatch('updatePayments', payload)
			this.close()
		}
	}
}
</script>