<template>
	<pop-up-window
		:show="this.$store.state.displayEditPeppol"
		:showClose="false"
		@close="close"
		:width40="true"
		:top10="true"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Peppol List</template>
		<template #content>
			<div class="one-line bottom-grey-line">
				<p class="font-bold label-style">Peppol identifier: </p>
				<div class="input-style">
					<Dropdown
						@update-value="updateSelectedPeppolIdentifier"
						class=""
						:data="dropdownList"
						:selected=selectedPeppolIdentifier
					/>
				</div>
			</div>

			<div v-if="displayModify">
				<p class="font-bold mb-2"> Update Peppol identifier:</p>
				<div class="modify-fields">
					<div class="one-line">
						<p class="font-bold label-style">ID: </p>
						<div class="input-style">
							<div class="styleInputWithError">
								<input
									:style="{borderColor: identifierIsEmpty? '#da4a4a' : ''}"
									v-model="identifier"
									type="text"
									class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
									@input="validate"
								/>
								<span v-if="identifierIsEmpty" class="text-primary-3">Peppol identifier is required.</span>
							</div>
						</div>
					</div>

					<div class="one-line">
						<p class="font-bold label-style">Name: </p>
						<div class="input-style">
							<input
								:style="{borderColor: nameIsEmpty? '#da4a4a' : ''}"
								v-model="peppolIdentifiers[identifier].name"
								type="text"
								class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
								@input="validate"
							/>
							<span v-if="nameIsEmpty" class="text-primary-3">Peppol name is required.</span>
						</div>
					</div>

					<div class="toggle-container" style="margin-bottom: 10px">
						<label class="font-bold form-label" style="width: 80%">Is receiver: </label>
						<toggle-slide
							style="width: 20%"
							:bool="peppolIdentifiers[identifier].isReceiver"
							:renew-bool="true"
							@update-value="updateBool($event,'isReceiver')"
						></toggle-slide>
					</div>
					<div class="toggle-container">
						<label class="font-bold form-label" style="width: 80%">Is sender:</label>
						<toggle-slide
							style="width: 20%"
							:bool="peppolIdentifiers[identifier].isSender"
							:renew-bool="true"
							@update-value="updateBool($event,'isSender')"
						></toggle-slide>
					</div>
					<div style="margin-top: 10px;">
						<label class="font-bold form-label">Country code:</label>
						<Dropdown
							class=""
							:data="countryCodes"
							:selected=peppolIdentifiers[identifier].countryCode
							@update-value="updateCountryCode"
						/>
					</div>
				</div>

			</div>

			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2 nymus-blue color-white"
							v-if="displayModify"
							@click="modify('update')">
						Update
					</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white"
							v-if="displayModify"
							@click="modify('delete')">
						Delete
					</button>
					<button class="btn shadow-md mr-2" @click="close">Cancel</button>
				</div>
			</div>
			<ConfirmDialog @close="deletePeppolIdentifier" :show="showConfirm"/>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import Dropdown from "../Dropdown.vue";
import ConfirmDialog from "../ConfirmDialog";
import ToggleSlide from "@/components/ToggleSlide.vue";
import countryCodes from "@/countryCodes";

export default {
	name: "EditPeppol",
	components: {
		ToggleSlide,
		ConfirmDialog,
		PopUpWindow,
		Dropdown,
	},
	props: {
		dropdownList: Array,
	},
	data() {
		return {
			showConfirm: false,
			peppolIdentifiers: [],
			selectedPeppolIdentifier: "Select Peppol identifier",
			identifier: '',
			nameIsEmpty: false,
			identifierIsEmpty: false,
		}
	},
	watch: {
		peppolIdentifiersList(newVal) {
			this.peppolIdetifiers = newVal;
		},
		"$store.state.activeLE": {
			handler(data){
				this.peppolIdentifiers = []
				this.initData(data.peppolParticipants)
			}
		},
		"$this.$store.state.activeLE.peppolParticipants": {
			handler(data) {
				this.peppolIdentifiers = []
				this.initData(data.peppolParticipants)
			}
		}
	},
	beforeMount() {
		if (this.$store.state.activeLE.peppolIdentifiers) {
			this.peppolIdentifiers = [...this.$store.state.activeLE.peppolIdentifiers]
		}
		if (this.$store.state.activeLE.peppolParticipants) {
			this.initData(this.$store.state.activeLE.peppolParticipants)
		}
	},
	computed: {
		displayModify() {
			return this.selectedPeppolIdentifier !== "Select Peppol identifier"
		},
		countryCodes() {
			return countryCodes
		}
	},

	methods: {
		initData(data){
			for (let identifier in data){
				this.peppolIdentifiers[identifier] = {
					name: this.$store.state.activeLE.peppolParticipants[identifier].name,
					isSender: this.$store.state.activeLE.peppolParticipants[identifier].isSender,
					isReceiver: this.$store.state.activeLE.peppolParticipants[identifier].isReceiver,
					countryCode: this.$store.state.activeLE.peppolParticipants[identifier].countryCode
				}
			}
		},
		setEditData() {
			if (this.selectedPeppolIdentifier.includes("-")) {
				this.name = this.selectedPeppolIdentifier.split('-')[1];
			} else {
				if (this.selectedPeppolIdentifier.trim() === "") this.name = '';
				else this.name = this.selectedPeppolIdentifier
			}
		},
		updateInputCountryCode(data){
			if (data[0] !== -1){
				this.peppolIdentifiers[this.selectedPeppolIdentifier.split('-')[0].trim()].countryCode = countryCodes[data[0]].name
			}
		},
		updateSelectedPeppolIdentifier(data) {
			if (data[0] === -1) return

			if (this.dropdownList[data[0]].name === "Select Peppol identifier") {
				this.selectedPeppolIdentifier = "Select Peppol identifier"
				return
			}

			this.identifier = this.dropdownList[data[0]].name.split('-')[0]
			this.selectedPeppolIdentifier = this.dropdownList[data[0]].name

			this.setEditData()
		},
		updateBool(data, itemName) {
			if (itemName === 'isReceiver') this.peppolIdentifiers[this.selectedPeppolIdentifier.split('-')[0].trim()].isReceiver = data
			else this.peppolIdentifiers[this.selectedPeppolIdentifier.split('-')[0].trim()].isSender = data
		},
		updateCountryCode(data) {
			if (typeof data === "string"){
				this.peppolIdentifiers[this.identifier].countryCode = this.countryCodes[data].name
			}

		},
		modify(type) {

			if (this.identifierIsEmpty === false && this.nameIsEmpty === false) {
				if (type === "delete") this.showConfirm = true;
				else if (type === 'update') {
					let payload = {};
					let test = {
						... this.peppolIdentifiers
					}
					payload = {
						peppolParticipants: {peppolParticipants: test},
						sgId: this.$store.state.activeSG.supplierGroupId,
						leId: this.$store.state.activeLE.entityNumber,
					}

					this.$store.dispatch('peppol/addPeppolIdentifier', payload);
					this.close()
				}
			}

		},
		validate() {
			this.identifierIsEmpty = this.identifier.trim() === '';
			this.nameIsEmpty = this.name.trim() === '';
		},
		deletePeppolIdentifier(data) {
			if (data === 'Yes') {
				let payload = {};
				delete this.peppolIdentifiers[this.identifier]

				let test = {
					...this.peppolIdentifiers
				}

				payload = {
					peppolParticipants: {peppolParticipants: test},
					sgId: this.$store.state.activeSG.supplierGroupId,
					leId: this.$store.state.activeLE.entityNumber,
				}

				this.$store.dispatch('peppol/addPeppolIdentifier', payload);
				this.close()
			} else {
				this.showConfirm = false;
			}
		},
		close() {
			this.$store.state.displayEditPeppol = false;
			this.selectedPeppolIdentifier = "Select Peppol identifier";
			this.identifier = '';
			this.name = "";
			this.showConfirm = false
			this.identifierIsEmpty = false;
			this.nameIsEmpty = false;
		}
	}
}

</script>

<style scoped>

.one-line {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 16px;
}

.input-style {
	width: 400px;
	margin-left: 10px;
}

.label-style {
	width: 140px;
}

.modify-fields {
	/*display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;*/
}

.bottom-grey-line {
	padding-bottom: 16px;
	border-bottom: 1px solid grey;
}
</style>
