<template>
    <div class="box shadow-none col-span-6">
        <div class="text-left lg:text-left p-5  border-t border-gray-200 dark:border-dark-5">
            <div class="button-container">
                <button
                      class="btn shadow-md bg-theme-1 text-white mr-2"
                      @click="addNewMailTemplate"
				>
                    Add new template
                </button>
            </div>
            <div class="flex flex-col flex-nowrap" v-if="displayTemplates">
                <div class="h-full mb-3" >
                    <div>
                        <p class="font-bold form-label">
                            Select mail template:
                        </p>

                        <Dropdown
                              @update-value="updateSelectedName"
                              class="w-1/2 pr-6"
                              :data="mailTemplateOptions"
                              :selected="this.$store.state.selectedMailTemplateName"
                        />
                    </div>

                    <label for="mailTemplateBanner" class="font-bold form-label  mt-2">
						Banner:
                    </label>
                    <img
                          id="mailTemplateBanner"
                          class="max-h-96 mail-banner"
                          :src="activeTemplate.banner"
                          alt="Banner"
                    />
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-col w-1/2 mr-12">
                        <Field
                              id="mailTemplateSenderEmail"
                              title="Sender email:"
                              type="text"
                              :modelValue="activeTemplate.senderEmail"
                              :disabled="true"
                              :copySuffix="true"
                              :isCopied="copySenderEmail"
                              @copy="copy('senderEmail')"
                        />
                        <div class="mb-3">
                            <label for="mailTemplateLanguages" class="font-bold form-label">
								Languages:
							</label>
                            <DropdownSelectAll
                                  v-if="renderComponent"
                                  :data="languages"
                                  :key="activeTemplate.emailLanguages"
                                  :selected="activeTemplate.emailLanguages"
                                  :disabled="true"
                                  :noSelectAll="true"
                            />
                        </div>
						<div class="mb-3">
							<label for="editMailTemplateLanguages" class="font-bold form-label">
								Default Languages:
							</label>
							<Dropdown
								:data="defaultLanguageOptions"
								:selected="activeTemplate.defaultEmailLanguage"
								:disabled="true"
							/>
						</div>
                        <div>
                            <p class="font-bold form-label">Mail doc types:*</p>
                            <DropdownSelectAll
                                  v-if="renderComponent"
                                  id="viewMailTemplateDocTypes"
                                  :data="docTypes"
                                  :disabled="true"
                                  :selected="activeTemplate.docTypes"
                                  :noSelectAll="true"
                            />
                        </div>
                    </div>
                    <div class="flex flex-col w-1/2">
                        <Field
                              id="mailTemplateDisclaimer"
                              title="Disclaimer email:"
                              type="text"
                              :modelValue="activeTemplate.disclaimerEmail"
                              :disabled="true"
                              :copySuffix="true"
                              :isCopied="copyDisclaimerEmail"
                              @copy="copy('disclaimerEmail')"
                        />
                        <div class="mb-3">
                            <label for="mailTemplateAddress" class="font-bold form-label">
                                Address information:
                            </label>
                            <div class="flex flex-row">
                                <button
                                      class="btn bg-theme-1 text-white w-24 mr-1 mb-2"
                                      @click="copy('address')"
                                >
                                    Copy
                                </button>
                                <button
                                      class="btn bg-theme-1 text-white w-24 mr-1 mb-2"
                                      @click="viewAddressInformation"
                                >
                                    View
                                </button>
                            </div>
                            <p class="font-bold">Preview:</p>
                            <div v-html="activeTemplate.address"></div>
                        </div>
                    </div>
                </div>

            </div>
            <p v-else>No mail templates yet</p>
        </div>
    </div>
    <add-mail-template
          :lang = 'languages'
          :isFor = "isFor">
    </add-mail-template>
    <ViewHtml/>
</template>
<script>
import Field from "../../Field.vue";
import ViewHtml from "../../ViewHtml.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import Dropdown from "../../Dropdown.vue"
import AddMailTemplate from "./AddMailTemplate.vue";
import languages from "@/langCodes";
export default {
    name: "ViewMailTemplate",
    components: {
        AddMailTemplate,
        Field,
        ViewHtml,
        DropdownSelectAll,
        Dropdown
    },
    props: {
        isFor: String,
        languages: Array,
    },
    watch: {
        "$store.state.activeSG": {
            handler(data) {
                if (this.isFor === "supplierGroup") {
                    this.activeItem = data;
                    this. initData();
                    this.forceRerender();
                }
            },
            deep: true,
        },
        "$store.state.activeLE": {
            handler(data) {
                if (this.isFor === "legalEntity") {
                    this.activeItem = data;
                    this. initData();
                    this.forceRerender();
                }
            },
            deep: true,
        },
        '$store.state.selectedMailTemplateName': {
            handler(name) {
                this.setActiveTemplate(name);
            },
            immediate: true,
        },
    },
	computed: {
		defaultLanguageOptions(){
			let defaultLanguages = [...languages]
			if (defaultLanguages[0].name !== 'No default language'){
				defaultLanguages.unshift({name: 'No default language'})
			}
			return defaultLanguages
		},
	},
    data() {
        return {
            formData : [],
            mailTemplateOptions : [],
            displayTemplates: false,
            activeTemplate : {},
            activeItem: {},
            copySenderEmail: false,
            copyDisclaimerEmail: false,
            docTypes: this.$store.state.docTypes,
            renderComponent : true,
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        addNewMailTemplate(){
            this.$store.commit('displayAddMailTemplate', true)
        },

        copy(isFor) {
            switch (isFor) {
                case "senderEmail":
                    navigator.clipboard.writeText(this.activeTemplate.senderEmail);
                    this.copySenderEmail = true;
                    setTimeout(() => {
                        this.copySenderEmail = false;
                    }, 750);
                    break;
                case "disclaimerEmail":
                    navigator.clipboard.writeText(this.activeTemplate.disclaimerEmail);
                    this.copyDisclaimerEmail = true;
                    setTimeout(() => {
                        this.copyDisclaimerEmail = false;
                    }, 750);
                    break;
                case "address":
                    navigator.clipboard.writeText(this.activeTemplate.address)
                    break;
                default:
                    break;
            }
        },
        viewAddressInformation() {
            this.$store.state.viewHtmlContent = this.activeTemplate.address
            this.$store.state.displayShowHtml = true;
        },
        initData() {
            if (this.isFor === "supplierGroup") {
                this.activeItem = this.$store.state.activeSG;
            }
            if (this.isFor === "legalEntity") {
                this.activeItem = this.$store.state.activeLE;
            }
            if (this.activeItem.templates && this.activeItem.templates.mail){
                this.formData = [JSON.parse(JSON.stringify(this.activeItem.templates.mail))]
            }
            if (this.activeItem.templates && this.activeItem.templates.mails){
                this.formData = JSON.parse(JSON.stringify(this.activeItem.templates.mails));
            }

			this.formData.forEach(mail => {
				if (mail.defaultEmailLanguage === undefined) mail.defaultEmailLanguage = 'No default language'
			})

            this.setEmailTemplateList(this.formData);
            if (this.mailTemplateOptions.length > 0){
                this.setSelectedMailTemplateName(this.mailTemplateOptions[0].name);
                this.setActiveTemplate(this.mailTemplateOptions[0].name);
            }

            this.displayTemplates = this.formData.length !== 0;
        },
        setEmailTemplateList(mailTemplates){
            this.mailTemplateOptions = []
            if (mailTemplates.length !== 0){
                mailTemplates.forEach( (template, index) =>{
                    this.mailTemplateOptions.push({
                        id: index,
                        name : template.name
                    })
                });
            }
        },
        setSelectedMailTemplateName(templateName){
            this.$store.commit('setSelectedMailTemplateName', templateName)
        },
        setActiveTemplate(name){
            let foundTemplate = this.formData.find(template=>(template.name === name))
            this.activeTemplate = foundTemplate || {};
        },
        updateSelectedName(data) {
			let foundName
			if (typeof data === 'string'){
				foundName =  this.mailTemplateOptions.find((item) => item.id.toString() === data).name;
			}
			else {
				foundName =  this.mailTemplateOptions.find((item) => (item.id.toString() === data[0].toString())).name;
			}
            this.$store.commit("setSelectedMailTemplateName", foundName);
            this.setActiveTemplate(foundName);
            this.forceRerender();
        },
        forceRerender() {
            // Removing my-component from the DOM
            this.renderComponent = false;

            this.$nextTick(() => {
                // Adding the component back in
                this.renderComponent = true;
            });
        },
    },
};
</script>
<style scoped>
.mail-banner {
    max-height: 100px;
    max-width: 100vw;
    height: auto;
    width: auto;
}
.button-container{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    justify-items: end;
}
</style>
