<template>
	<pop-up-window
		:show="this.$store.state.displayEditPeppolDocuments"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
		@add-receiverMail='addEmail(data)'
	>
		<template #header>Edit Peppol Documents</template>
		<template #content>
			<div style="display: flex" :style="{flexDirection: isExternalFTPChecked} ">
				<div style="width: 50%;">
					<!--Peppol configuration-->
					<div style="display: flex; flex-direction: column">
						<div class="one-line" style="margin-bottom: 10px">
							<p class="font-bold mr-2">Peppol configuration:</p>
							<ToggleSlide
								class="w-min"
								@update-value="updateToggleValue($event, 'peppolConfiguration')"
								:bool="toggleBooleans.peppolConfiguration"
							/>
						</div>
						<!--External FTP-->
						<div v-if="toggleBooleans.peppolConfiguration" style="margin-left: 20px">
							<div class="one-line" style="margin-bottom: 10px">
								<p class="font-bold mr-2">External FTP:</p>
								<ToggleSlide
									class="w-min"
									@update-value="updateToggleValue($event, 'externalFTP', true)"
									:bool="toggleBooleans.externalFTP"
								/>
							</div>
							<div v-if="toggleBooleans.externalFTP">
								<div class="ml-6">
									<div>
										<div style="margin-top: 5px; margin-bottom: 5px">
											<p class="font-bold">Peppol type:</p>
											<DropdownSelectAll
												v-model="formData.externalFTPConfig.peppolMessageTypes"
												@update-value="updateDocTypeIdentifier($event, 'externalFTPConfig')"
												:data="peppolTypeIdentifiers"
												:selected="selectedPeppolTypeIdentifiers.externalFTP"
												:disabled="false"
												:noSelectAll="true"
											/>
										</div>
										<Field
											title="URL:"
											type="text"
											v-model.trim="formData.externalFTPConfig.externalFTP.url"
										/>
									</div>
									<Field
										title="Location:"
										type="text"
										v-model.trim="formData.externalFTPConfig.externalFTP.location"
									/>
									<Field
										title="User:"
										type="text"
										v-model.trim="formData.externalFTPConfig.externalFTP.user"
									/>
									<Field
										title="Password:"
										type="text"
										v-model.trim="formData.externalFTPConfig.externalFTP.password"
									/>
									<div>
										<p class="font-bold">FTP type:</p>
										<Dropdown
											@update-value="updateFtpType"
											:data="ftpTypeOptions"
											:selected="formData.externalFTPConfig.externalFTP.ftpType"
										/>
									</div>
									<Field
										title="Port:"
										type="number"
										v-model.number="formData.externalFTPConfig.externalFTP.port"
										@click="setErrors('externalFTPPort', false)"
									/>
									<p class="error" v-if="errors.externalFTP">Please, enter valid port value.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="toggleBooleans.peppolConfiguration"
					style="display: flex; flex-direction: column; margin-left: 10px; width: 45%"
					:style="{marginTop: isExternalFTPChecked === 'column' ? '0px' : '31px' }"
				>
					<!-- basware-->
					<div class=" one-line-input">
						<div class="one-line">
							<p class="font-bold mr-2">Basware: </p>
							<ToggleSlide
								class="w-min"
								@update-value="updateBasware($event)"
								:bool="toggleBooleans.basware"
							/>
						</div>
						<div class="ml-6 w-full" v-if="toggleBooleans.basware">
							<div v-if="toggleBooleans.basware" style="margin-top: 5px; margin-bottom: 5px">
								<p class="font-bold">Peppol type:</p>
								<DropdownSelectAll
									@update-value="updateDocTypeIdentifier($event, 'baswareConfig')"
									:data="peppolTypeIdentifiers"
									:selected="selectedPeppolTypeIdentifiers.baswareConfig"
									:disabled="false" :noSelectAll="true"/>
							</div>

							<p class="error" v-if="errors.baswareConfig">Basware peppol type can not be empty.</p>
						</div>
					</div>
					<!-- Internal FTP-->
					<div class=" one-line-input">
						<div class="one-line">
							<p class="font-bold mr-2">Internal FTP:</p>
							<ToggleSlide
								class="w-min"
								@update-value="updateToggleValue($event, 'internalFTP')"
								:bool="toggleBooleans.internalFTP"
							/>
						</div>
						<div class="ml-6 w-full" v-if="toggleBooleans.internalFTP">
							<div v-if="toggleBooleans.internalFTP" style="margin-top: 5px; margin-bottom: 5px">
								<p class="font-bold">Peppol type:</p>
								<DropdownSelectAll
									@update-value="updateDocTypeIdentifier($event, 'internalFTPConfig')"
									:data="peppolTypeIdentifiers"
									:selected="selectedPeppolTypeIdentifiers.internalFTP"
									:disabled="false" :noSelectAll="true"
								/>
							</div>
							<Field
								type="text"
								v-model.trim="formData.internalFTPConfig.internalFTP"
								@click="setErrors('internalFTP', false)"
							/>

							<p class="error" v-if="errors.internalFTP">Internal FTP and peppol type can not be empty.</p>
						</div>
					</div>
					<!--webhook-->
					<div class="one-line-input">
						<div class="one-line">
							<p class="font-bold mr-2">Webhook:</p>
							<ToggleSlide
								class="w-min"
								@update-value="updateToggleValue($event, 'webhook')"
								:bool="toggleBooleans.webhook"
							/>
						</div>
						<div class="ml-6 w-full" v-if="toggleBooleans.webhook">
							<div v-if="toggleBooleans.webhook" style="margin-top: 5px; margin-bottom: 5px">
								<p class="font-bold">Peppol type:</p>
								<DropdownSelectAll
									@update-value="updateDocTypeIdentifier($event, 'webhookConfig')"
									:data="peppolTypeIdentifiers"
									:selected="selectedPeppolTypeIdentifiers.webhookConfig"
									:disabled="false" :noSelectAll="true"
								/>
							</div>
							<Field
								type="text"
								v-model.trim="formData.webhookConfig.webhook"
								@click="setErrors('webhook', false)"
							/>
							<p class="error" v-if="errors.webhook">Webhook and pepol type can not be empty.</p>

							<div class="one-line">
								<p class="font-bold mr-2">Use put request:</p>
								<ToggleSlide
									class="w-min"
									@update-value="formData.webhookConfig.usePutRequest = !formData.webhookConfig.usePutRequest"
									:bool="formData.webhookConfig.usePutRequest"
									:renew-bool="true"
								/>
							</div>
						</div>

					</div>
					<!--API Key-->
					<div class="one-line-input">
						<div class="one-line">
							<p class="font-bold mr-2">API: </p>
							<ToggleSlide
								class="w-min"
								@update-value="updateToggleValue($event, 'apiKey')"
								:bool="toggleBooleans.apiKey"
							/>
						</div>

						<div class="ml-6 w-full" v-if="toggleBooleans.apiKey">
							<div>
								<div class="input-group mb-3">
									<input
										type="text"
										class="form-control"
										v-model="formData.apiKey"
									/>
									<div
										@click="generateKey"
										class="font-bold cursor-pointer input-group-text"
									>
										<font-awesome-icon icon="gears"/>
									</div>
								</div>

							</div>

						</div>
						<p class="error" v-if="errors.apiKey">API key can not be empty.</p>

					</div>
					<!--Email-->
					<div class="one-line-input">
						<div class="one-line">
							<p class="font-bold mr-2">Email:</p>
							<ToggleSlide
								class="w-min"
								@update-value="updateToggleValue($event, 'email')"
								:bool="toggleBooleans.email"
							/>
						</div>

						<div class="ml-5 w-full" v-if="toggleBooleans.email">
							<div>
								<div v-if="toggleBooleans.email" style="margin-top: 5px; margin-bottom: 5px">
									<p class="font-bold">Peppol type:</p>
									<DropdownSelectAll
										@update-value="updateDocTypeIdentifier($event, 'emailConfig')"
										:data="peppolTypeIdentifiers"
										:selected="selectedPeppolTypeIdentifiers.emailConfig"
										:disabled="false" :noSelectAll="true"
									/>
								</div>
								<div>
									<!--Negative IMR only-->
									<div class="one-line">
										<p class="font-bold mr-2">Negative IMR only:</p>
										<ToggleSlide
											class="w-min"
											@update-value="changeNegativeIMROnly"
											:bool="formData.emailConfig.negativeIMROnly"
										/>
									</div>
									<p class="font-bold form-label mr-2">Mails:</p>
									<button
										@click="displayAddEmail"
										class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
									>
										Add
									</button>

									<div
										v-for="(item, index) of Object.keys(formData.emailConfig.emails)"
										:key="'editAdminEmail' + index"
										class="flex flex-row input-group w-full mb-2 pl-6"
									>
										<div class="input-group-text">
											{{ formData.emailConfig.emails[item] }}
										</div>
										<input
											type="text"
											:name="item"
											class="form-control"
											@change=" updateEmails($event.target.name,  $event.target.value)"
											:value="item"
										/>
										<div
											@click="deleteEmail(item)"
											class="font-bold cursor-pointer input-group-text"
										>
											<font-awesome-icon icon="trash-can"/>
										</div>
									</div>
								</div>

							</div>
						</div>
						<p class="error" v-if="errors.email">Peppol type of email can not be empty.</p>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
			<add-email @add-receiverMail="addEmail" :languages="languages" :emailAdded=this.emailAdded></add-email>
		</template>
	</pop-up-window>
	<confirmation-with-props
		description="Are you sure you want to add a webhook without selecting an API key?"
		:show-dialog="showConfirmDialog"
		@approve-action="confirmUpdate"
		@cancel-action="showConfirmDialog = false"
	/>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import Field from "../Field.vue";
import Dropdown from "../Dropdown.vue";
import ToggleSlide from "../ToggleSlide.vue";
import langCodes from "../../langCodes";
import AddEmail from "@/components/EditSupplierGroup/AddEmail"
import {v4 as uuidv4} from 'uuid';
import DropdownSelectAll from "@/components/DropdownSelectAll.vue";
import {errors} from "browser-sync/dist/config";
import ConfirmationWithProps from "@/components/ConfirmationDialogWithProps.vue";

export default {
	name: "EditPeppolDocuments",
	components: {
		ConfirmationWithProps,
		DropdownSelectAll,
		PopUpWindow,
		Field,
		Dropdown,
		ToggleSlide,
		AddEmail
	},
	props: {
		isFor: {
			required: true,
			type: String,
		}
	},

	data() {
		return {
			emailAdded: false,
			languages: langCodes,
			showConfirmDialog: false,
			actionApproved: false,
			ftpTypeOptions: [
				{id: 0, name: "FTPS"},
				{id: 1, name: "SFTP"},
				{id: 2, name: "FTP"},
			],
			toggleBooleans: {
				peppolConfiguration: false,
				basware: false,
				internalFTP: false,
				externalFTP: false,
				webhook: false,
				apiKey: false,
				email: false
			},
			errors: {
				peppolConfiguration: false,
				internalFTP: false,
				webhook: false,
				apiKey: false,
				baswareConfig: false,
				externalFTP: false,
				email: false
			},

			formData: {
				apiKey: '',
				internalFTPConfig: {
					internalFTP: "",
					peppolMessageTypes: []
				},
				externalFTPConfig: {
					externalFTP: {
						url: "",
						location: "",
						user: "",
						password: "",
						ftpType: "FTPS", //Preselect FTPS ; SFTP,FTP,FTPS
						port: 21 //Preselect 21
					},
					peppolMessageTypes: [],

				},
				webhookConfig: {
					webhook: '',
					peppolMessageTypes: []
				},

				emailConfig: {
					negativeIMROnly: false,
					emails: {},
					peppolMessageTypes: []
				},
				baswareConfig: {
					peppolMessageTypes: []
				}
			},
			isExternalFTPChecked: 'column',
			peppolTypeIdentifiers: this.$store.state.peppolMessageType,
			selectedPeppolTypeIdentifiers: {
				externalFTP: [],
				internalFTP: [],
				webhookConfig: [],
				emailConfig: [],
				baswareConfig: []
			},
		}
	},
	computed: {
		activeSG() {
			return this.$store.state.activeSG
		},
		activeLE() {
			return this.$store.state.activeLE
		}
	},
	watch: {
		activeSG() {
			this.clearErrors()
			this.setFormData()
		},
		activeLE() {
			this.clearErrors()
			this.setFormData()
		}
	},
	mounted() {
		this.clearErrors()
		this.setFormData()
	},
	methods: {
		updateDocTypeIdentifier(event, test) {
			if (event && event !== '') {
				if (!event.includes(-1)) {
					this.formData[test].peppolMessageTypes = []
					event.forEach(i => this.formData[test].peppolMessageTypes.push(this.peppolTypeIdentifiers[i].name))
				}
			}
		},
		generateKey() {
			this.formData.apiKey = uuidv4();
		},
		addEmail(data) {
			this.formData.emailConfig.emails[data.email] = data.language
			this.emailAdded = true;
		},
		displayAddEmail() {
			this.emailAdded = false;
			this.$store.state.displayAddEmail = true;
		},
		deleteEmail(item) {
			delete this.formData.emailConfig.emails[item];
		},
		updateEmails(name, item) {
			this.formData.emailConfig.emails[item] = this.formData.emailConfig.emails[name];
			delete this.formData.emailConfig.emails[name];
		},
		changeNegativeIMROnly(data) {
			this.formData.emailConfig.negativeIMROnly = data;
		},

		updateBasware(data) {
			this.toggleBooleans.basware = !this.toggleBooleans.basware
			this.formData.baswareConfig.peppolMessageTypes = data
		},
		updateToggleValue(data, field) {
			this.toggleBooleans[field] = data;

			this.setErrors('peppolConfiguration', false)
			if (field === 'apiKey' || field === 'webhook' || field === 'internalFTP' || field === 'peppolConfiguration' || field === 'basware') {
				this.setErrors(field, false)
			}

			if (field === "peppolConfiguration" && this.toggleBooleans.peppolConfiguration === false) {
				this.toggleBooleans.internalFTP = false
				this.toggleBooleans.externalFTP = false
				this.toggleBooleans.webhook = false
				this.toggleBooleans.apiKey = false
				this.toggleBooleans.email = false
				this.toggleBooleans.basware = false

				this.formData = {
					apiKey: '',
					internalFTPConfig: {
						internalFTP: "",
						peppolMessageTypes: []
					},
					externalFTPConfig: {
						externalFTP: {
							url: "",
							location: "",
							user: "",
							password: "",
							ftpType: "FTPS", //Preselect FTPS ; SFTP,FTP,FTPS
							port: 21 //Preselect 21
						},
						peppolMessageTypes: [],

					},
					webhookConfig: {
						webhook: '',
						peppolMessageTypes: [],
						usePutRequest: false
					},

					emailConfig: {
						negativeIMROnly: false,
						emails: {},
						peppolMessageTypes: []
					},
					baswareConfig: {
						peppolMessageTypes: []
					}
				}
			}

			if (this.toggleBooleans.externalFTP) {
				this.isExternalFTPChecked = 'row'
			} else if (!this.toggleBooleans.externalFTP) {
				this.isExternalFTPChecked = 'column'
			}
		},

		updateFtpType(data) {
			this.formData.externalFTPConfig.externalFTP.ftpType = this.ftpTypeOptions[data].name;
		},
		setFormData() {
			this.toggleBooleans.peppolConfiguration = false;
			if (this.isFor === "supplierGroup" && this.$store.state.activeSG.peppolConfiguration) {
				this.formData = JSON.parse(JSON.stringify(this.$store.state.activeSG.peppolConfiguration));
				this.toggleBooleans.internalFTP = !!this.formData.internalFTPConfig
				this.toggleBooleans.externalFTP = !!this.formData.externalFTPConfig
				this.toggleBooleans.webhook = !!this.formData.webhookConfig
				this.toggleBooleans.apiKey = !!this.formData.apiKey
				this.toggleBooleans.email = !!this.formData.emailConfig
				this.toggleBooleans.basware = !!this.formData.baswareConfig
			}

			if (this.isFor === "legalEntity" && this.$store.state.activeLE.peppolConfiguration) {
				this.formData = JSON.parse(JSON.stringify(this.$store.state.activeLE.peppolConfiguration));
				if (Object.keys(this.$store.state.activeLE.peppolConfiguration).length) {
					this.toggleBooleans.internalFTP = !!this.formData.internalFTPConfig
					this.toggleBooleans.externalFTP = !!this.formData.externalFTPConfig
					this.toggleBooleans.webhook = !!this.formData.webhookConfig
					this.toggleBooleans.apiKey = !!this.formData.apiKey
					this.toggleBooleans.email = !!this.formData.emailConfig
					this.toggleBooleans.basware = !!this.formData.baswareConfig
				}
			}

			if (this.toggleBooleans.basware || this.toggleBooleans.email ||
				this.toggleBooleans.webhook || this.toggleBooleans.apiKey ||
				this.toggleBooleans.externalFTP || this.toggleBooleans.internalFTP) {
				this.toggleBooleans.peppolConfiguration = true
			}

			if (this.formData.internalFTPConfig) this.selectedPeppolTypeIdentifiers.internalFTP = this.formData.internalFTPConfig.peppolMessageTypes
			else {
				this.formData.internalFTPConfig = {
					internalFTP: '',
					peppolMessageTypes: []
				}
			}

			if (this.formData.baswareConfig) this.selectedPeppolTypeIdentifiers.baswareConfig = this.formData.baswareConfig.peppolMessageTypes
			else {
				this.formData.baswareConfig = {
					peppolMessageTypes: []
				}
			}

			if (this.formData.webhookConfig) {
				this.selectedPeppolTypeIdentifiers.webhookConfig = this.formData.webhookConfig.peppolMessageTypes
				if (this.formData.webhookConfig.usePutRequest === undefined) this.formData.webhookConfig.usePutRequest = false
			}
			else {
				this.formData.webhookConfig = {
					webhook: '',
					peppolMessageTypes: [],
					usePutRequest: false
				}
			}

			if (!(this.formData.apiKey)) this.formData.apiKey = ''

			if (this.formData.emailConfig) this.selectedPeppolTypeIdentifiers.emailConfig = this.formData.emailConfig.peppolMessageTypes
			else {
				this.formData.emailConfig = {
					negativeIMROnly: true,
					emails: {},
					peppolMessageTypes: []
				}
			}

			if (this.formData.externalFTPConfig) this.selectedPeppolTypeIdentifiers.externalFTP = this.formData.externalFTPConfig.peppolMessageTypes
			else {
				this.formData.externalFTPConfig = {
					externalFTP: {
						url: "",
						location: "",
						user: "",
						password: "",
						ftpType: "FTPS",
						port: 21,
					}
				}
				this.formData.externalFTPConfig.peppolMessageTypes = []
			}
			if (this.toggleBooleans.externalFTP) {
				this.isExternalFTPChecked = 'row'
			} else {
				this.isExternalFTPChecked = 'column'
			}
		},
		validate() {
			this.errors.baswareConfig = false
			this.errors.apiKey = false
			this.errors.webhook = false
			this.errors.externalFTP = false
			this.errors.internalFTP = false
			if (this.toggleBooleans.basware && this.formData.baswareConfig.peppolMessageTypes.length === 0) this.errors.baswareConfig = true
			if (this.toggleBooleans.webhook && (this.formData.webhookConfig.peppolMessageTypes.length === 0 || this.formData.webhookConfig.webhook === '')) this.errors.webhook = true
			if (this.toggleBooleans.internalFTP && (this.formData.internalFTPConfig.peppolMessageTypes.length === 0 || this.formData.internalFTPConfig.internalFTP === '')) this.errors.internalFTP = true
			if (this.toggleBooleans.externalFTP && this.formData.externalFTPConfig.peppolMessageTypes.length === 0) this.errors.externalFTP = true
			if (this.toggleBooleans.email && this.formData.emailConfig.peppolMessageTypes.length === 0) this.errors.email = true
			if (this.toggleBooleans.apiKey && this.formData.apiKey === "") this.errors.apiKey = true

			if (this.toggleBooleans.externalFTP && (this.formData.externalFTPConfig.externalFTP.url === '' || this.formData.externalFTPConfig.externalFTP.user === ''
				|| this.formData.externalFTPConfig.externalFTP.location === '' || this.formData.externalFTPConfig.externalFTP.password === ''
				|| this.formData.externalFTPConfig.externalFTP.port === '')) {
				this.errors.externalFTP = true
			}

			return this.errors.baswareConfig || this.errors.apiKey ||
				this.errors.webhook || this.errors.externalFTP || this.errors.internalFTP || this.errors.email
		},
		confirmUpdate(){
			this.actionApproved = true
			this.showConfirmDialog = false
			this.update()
		},
		update() {
			const isError = this.validate()
			if (isError) return

			if (this.toggleBooleans.webhook && !this.toggleBooleans.apiKey && !this.actionApproved) {
				this.showConfirmDialog = true
				return
			}

			let formData = ''
			if (this.toggleBooleans.peppolConfiguration) {
				if (!this.toggleBooleans.internalFTP) delete this.formData.internalFTPConfig;

				if (!this.toggleBooleans.basware) delete this.formData.baswareConfig;

				if (!this.toggleBooleans.webhook) {
					delete this.formData.webhookConfig;
				}
				if (!this.toggleBooleans.apiKey) {
					delete this.formData.apiKey
				}

				if (!this.toggleBooleans.externalFTP) delete this.formData.externalFTPConfig;

				if (!this.toggleBooleans.email) delete this.formData.emailConfig

				formData = {
					"peppolConfiguration": this.formData
				}
			} else {
				formData = {
					"peppolConfiguration": null
				}
			}

			let payload = {
				requestData: formData,
				supplierGroupId: this.$store.state.activeSG.supplierGroupId
			}
			if (this.isFor === "legalEntity") {
				payload.legalEntityNumber = this.$store.state.activeLE.entityNumber
			}

			this.$store.dispatch("peppol/peppolConfig", payload);
			this.actionApproved = false
			this.$store.state.displayEditPeppolDocuments = false;

		},
		setErrors(field, value) {
			this.errors[field] = value;
		},
		clearErrors() {
			let errors = ['internalFTP', 'webhook', 'apiKey', 'peppolConfiguration', "externalFTP"];

			errors.forEach(error => {
				this.setErrors(error, false);
			})
		},

		close() {
			this.clearErrors()
			this.$store.state.displayEditPeppolDocuments = false;
			this.setFormData()
		}
	}
}
</script>

<style scoped>
.one-line {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.one-line-input {
	margin-bottom: 8px;
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: start
}

</style>
