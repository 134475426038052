<template>
	<pop-up-window
		:show="show"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit General</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-row">
					<label class="font-bold text-lg form-label mb-0 mr-2">Negative creditnote: </label>
					<ToggleSlide
						class="w-min"
						:bool="creditNoteNegative"
						@update-value="creditNoteNegative = !creditNoteNegative"
					/>
				</div>
			</div>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-row">
					<label class="font-bold text-lg form-label mb-0 mr-2">Add zero tax segments: </label>
					<ToggleSlide
						class="w-min"
						:bool="addZeroTaxSegments"
						@update-value="addZeroTaxSegments = !addZeroTaxSegments"
					/>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="cancel">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>
<script>

import ToggleSlide from "@/components/ToggleSlide.vue"
import PopUpWindow from "../PopUp.vue"
export default {
	name: "ViewIngestion",
	components: {ToggleSlide, PopUpWindow},
	props: ["show"],
	emits: ["close"],
	data() {
		return {
			creditNoteNegative: this.$store.state.activeLE.ingestionConfig?.creditNoteNegative ? this.$store.state.activeLE.ingestionConfig.creditNoteNegative : false,
			addZeroTaxSegments: this.$store.state.activeLE.ingestionConfig?.addZeroTaxSegments ? this.$store.state.activeLE.ingestionConfig.addZeroTaxSegments : false
		}
	},
	methods: {
		update(){
			const payload = {
				supplierGroupId: this.$store.state.activeSG.supplierGroupId,
				legalEntityId:  this.$store.state.activeLE.entityNumber,
				ingestionSettings: {
					creditNoteNegative: this.creditNoteNegative,
					addZeroTaxSegments: this.addZeroTaxSegments
				}
			}

			this.$store.dispatch('EditLegalEntityIngestion', payload)
			this.$emit("close")
		},
		cancel() {
			this.creditNoteNegative = this.$store.state.activeLE.ingestionConfig?.creditNoteNegative ? this.$store.state.activeLE.ingestionConfig.creditNoteNegative : false
			this.$emit("close")
		}
	}
}
</script>
