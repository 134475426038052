<template>
	<div class="w-full">
		<div class="content">
			<div
				:class="{'pointer-events-none': this.$store.state.currentUser.group === 'NymusViewer'}"
				class="intro-y flex flex-col col-span-12 mt-5 box md:col-span-6 lg:col-span-4"
			>
				<div class="flex flex-row">
					<!-- SPEOS -->
					<div class="box w-2/6 mr-2">
						<div class="flex items-start px-5 pt-5">
							<h2 class="font-medium text-base mr-auto">SPEOS</h2>
						</div>
						<div class=" text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
							<div class="flex flex-col">
								<div class="flex flex-col mr-2">
									<p class="font-bold mb-1">Trigger print</p>
									<button
										@click="speosEvents('printSpeos')"
										class="btn btn-sm btn-bl w-24 mr-1 mb-2 nymus-blue"
										:class="{ Clicked: bools.printSpeos }"
									>
										Start
									</button>
								</div>
								<div class="flex flex-row">
									<div class="flex flex-col mr-2">
										<p class="font-bold mb-1">Print Schedule</p>
										<button
											@click="toggleGlobalJob('PRINT_SPEOS', 'Print Schedule SPEOS')"
											:class="boolScheduleSPEOS ? 'running' : 'not-running'"
											class="btn btn-sm w-24 mr-1 mb-2"
										>
											{{ boolScheduleSPEOS ? "Running" : "Not Running" }}
										</button>
									</div>
									<div class="flex flex-col">
										<p class="font-bold mb-1">Feedback Schedule</p>
										<button
											@click=" toggleGlobalJob('PRINT_FEEDBACK_SPEOS','Print Feedback Schedule SPEOS')"
											:class="boolFeedbackScheduleSPEOS ? 'running' : 'not-running'"
											class="btn btn-sm w-24 mr-1 mb-2"
										>
											{{ boolFeedbackScheduleSPEOS ? "Running" : "Not Running" }}
										</button>
									</div>
								</div>
								<div>
									<div class="flex flex-col">
										<p class="font-bold mb-1">Trigger feedback</p>
										<button
											class="btn btn-sm text-white w-24 mr-1 mb-2 nymus-blue"
											:class="{ Clicked: bools.printSpeosFeedback }"
											@click="speosEvents('printSpeosFeedback')"
										>
											Start
										</button>
									</div>
									<div class="flex flex-col">
										<FileUpload
											style="width: 75%"
											@update-value="setData"
											:data="reprocessValue"
											:isSpeosReprocess="true"
											id="speosFeedbackReprocess"
											title="Trigger file feedback"
											accept=".zip"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--EASYPOST-->
					<div class="box w-1/6 mr-2">
						<div class="flex items-start px-5 pt-5">
							<h2 class="font-medium text-base mr-auto">EASYPOST</h2>
						</div>
						<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
							<div>
								<p class="font-bold mb-1">Trigger feedback</p>
								<button
									class="btn btn-sm text-white w-24 mr-1 mb-2 nymus-blue"
									@click="triggerEasypostFeedback"
								>
									Start
								</button>
							</div>
							<div class="flex flex-col">
								<p class="font-bold mb-1">Feedback Schedule</p>
								<button
									@click=" toggleGlobalJob('EP_FEEDBACK','Print Feedback Schedule EASYPOST')"
									:class="boolFeedbackScheduleEASYPOST ? 'running' : 'not-running'"
									class="btn btn-sm w-24 mr-1 mb-2"
								>
									{{ boolFeedbackScheduleEASYPOST ? "Running" : "Not Running" }}
								</button>
							</div>
						</div>

					</div>
					<!--Twikey feed schedules-->
					<div class="box w-2/6">
						<div class="flex items-start px-5 pt-5">
							<h2 class="font-medium text-base mr-auto">
								TWIKEY FEED SCHEDULES
							</h2>
						</div>
						<div class=" text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
							<div class="flex flex-col">
								<p class="font-bold mb-1">Mandate</p>
								<div class="flex flex-row">
									<button
										@click="twikeyMandateSchedule(true,'twikeyMandateStart')"
										class="btn-bl btn btn-sm w-24 mr-2 mb-2 nymus-blue"
										:class="{ Clicked: bools.twikeyMandateStart }"
									>
										Start
									</button>
									<button
										@click="twikeyMandateSchedule(false,'twikeyMandateStop')"
										class="btn-bl btn btn-sm w-24 mr-1 mb-2 nymus-blue"
										:class="{ Clicked: bools.twikeyMandateStop }"
									>
										Stop
									</button>
								</div>

								<p class="font-bold mb-1">Transactions</p>
								<div class="flex flex-row">
									<button
										@click="twikeyTranactionSchedule(true,'twikeyTransactionStart')"
										class="btn-bl btn btn-sm w-24 mr-2 mb-2 nymus-blue"
										:class="{ Clicked: bools.twikeyTransactionStart }"
									>
										Start
									</button>
									<button
										@click="twikeyTranactionSchedule(false,'twikeyTransactionStop')"
										class="btn-bl btn btn-sm w-24 mr-1 mb-2 nymus-blue"
										:class="{ Clicked: bools.twikeyTransactionStop }"
									>
										Stop
									</button>
								</div>

								<p class="font-bold mb-1">Invoice</p>
								<div>
									<button
										@click="twikeyFeedPolling(true,'twikeyPollingStart')"
										class="btn-bl btn btn-sm w-24 mr-2 mb-2 nymus-blue"
										:class="{ Clicked: bools.twikeyPollingStart }"
									>
										Start
									</button>
									<button
										@click="twikeyFeedPolling(false,'twikeyPollingStop')"
										class="btn-bl btn btn-sm w-24 mr-1 mb-2 nymus-blue"
										:class="{ Clicked: bools.twikeyPollingStop }"
									>
										Stop
									</button>
								</div>
							</div>
						</div>
					</div>
					<!--SG printing & ingestion-->
					<div class="box w-2/6">
						<div class="flex items-start px-5 pt-5">
							<h2 class="font-medium text-base mr-auto">
								SG PRINTING &amp; INGESTION
							</h2>
						</div>
						<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
							<div class="flex flex-col">
								<p class="font-bold mb-1">Ingestion Schedule All</p>
								<div class="flex flex-row">
									<button
										id="ingestionStart"
										@click="ingestionScheduleAll($event)"
										class="btn-bl btn btn-sm w-24 mr-2 mb-2 nymus-blue"
										:class="{ Clicked: bools.ingestionStart }"
									>
										Start
									</button>
									<button
										id="ingestionStop"
										@click="ingestionScheduleAll($event)"
										class="btn-bl btn btn-sm w-24 mr-1 mb-2 nymus-blue"
										:class="{ Clicked: bools.ingestionStop }"
									>
										Stop
									</button>
								</div>

								<p class="font-bold mb-1">Peppol ingestion</p>
								<div>
									<button
										@click="triggerPeppollIngestion"
										class="btn-bl btn btn-sm w-24 mr-2 mb-2 nymus-blue"
									>
										Triger
									</button>
								</div>

								<p class="font-bold mb-1">PostDrop ingestion</p>
								<div>
									<button @click="triggerPostDropIngestion"
											class="btn-bl btn btn-sm w-24 mr-2 mb-2 nymus-blue">
										Triger
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex flex-row">
					<!-- SG -->
					<!--REPORTING-->
					<div class="box w-1/3 mt-2 mr-2 ">
						<div class="">
							<div class="flex items-start px-5 pt-5">
								<h2 class="font-medium text-base mr-auto">REPORTING SCHEDULE</h2>
							</div>
							<div
								class=" text-left lg:text-left p-5  border-t border-gray-200 dark:border-dark-5 reporting-schedule">
								<div class="flex flex-col">
									<p class="font-bold mb-1">Delivery errors</p>
									<button
										@click="toggleJob('MAIL_ERROR_REPORT','Delivery error report schedule' )"
										:class="jobStatuses['MAIL_ERROR_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{ jobStatuses['MAIL_ERROR_REPORT'].status ? "Running" : "Not Running" }}
									</button>
								</div>
								<div class="flex flex-col">
									<p class="font-bold mb-1">Channel-detail</p>
									<button
										@click=" toggleJob('CHANNEL_DETAIL_REPORT','Channel-detail report')"
										:class="jobStatuses['CHANNEL_DETAIL_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{ jobStatuses['CHANNEL_DETAIL_REPORT'].status ? "Running" : "Not Running" }}
									</button>
								</div>
								<div class="flex flex-col">
									<p class="font-bold mb-1">Channel-archived-detail</p>
									<button
										@click=" toggleJob('CHANNEL_ARCHIVED_DETAIL_REPORT','Channel-archived-detail report')"
										:class="jobStatuses['CHANNEL_ARCHIVED_DETAIL_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{
											jobStatuses['CHANNEL_ARCHIVED_DETAIL_REPORT'].status ? "Running" : "Not Running"
										}}
									</button>
								</div>
								<div class="flex flex-col">
									<p class="font-bold mb-1">Mandate overview</p>
									<button
										@click=" toggleJob('MANDATE_OVERVIEW_REPORT','Mandate overview report')"
										:class="jobStatuses['MANDATE_OVERVIEW_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{ jobStatuses['MANDATE_OVERVIEW_REPORT'].status ? "Running" : "Not Running" }}
									</button>
								</div>

								<div class="flex flex-col">
									<p class="font-bold mb-1">Failed transactions</p>
									<button
										@click=" toggleJob('TRANSACTION_OVERVIEW_REPORT','Failed transaction overview report')"
										:class="jobStatuses['TRANSACTION_OVERVIEW_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{
											jobStatuses['TRANSACTION_OVERVIEW_REPORT'].status ? "Running" : "Not Running"
										}}
									</button>
								</div>

								<div class="flex flex-col">
									<p class="font-bold mb-1">Channel choice</p>
									<button
										@click=" toggleJob('CHANNEL_CHOICE_REPORT','Channel choice  report')"
										:class="jobStatuses['CHANNEL_CHOICE_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{ jobStatuses['CHANNEL_CHOICE_REPORT'].status ? "Running" : "Not Running" }}
									</button>
								</div>
								<div class="flex flex-col">
									<p class="font-bold mb-1">PostDrop error</p>
									<button
										@click=" toggleJob('POST_DROP_ERROR_REPORT','PostDrop error report')"
										:class="jobStatuses['POST_DROP_ERROR_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{ jobStatuses['POST_DROP_ERROR_REPORT'].status ? "Running" : "Not Running" }}
									</button>
								</div>

								<div class="flex flex-col">
									<p class="font-bold mb-1">SG specific errors</p>
									<button
										@click=" toggleJob('SG_SPECIFIC_ERRORS_REPORT','SG_SPECIFIC_ERRORS_REPORT')"
										:class="jobStatuses['SG_SPECIFIC_ERRORS_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{
											jobStatuses['SG_SPECIFIC_ERRORS_REPORT'].status ? "Running" : "Not Running"
										}}
									</button>
								</div>
								<div class="flex flex-col">
									<p class="font-bold mb-1">UBL status</p>
									<button
										@click=" toggleJob('UBL_STATUS_REPORT','UBL_STATUS_REPORT')"
										:class="jobStatuses['UBL_STATUS_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{ jobStatuses['UBL_STATUS_REPORT'].status ? "Running" : "Not Running" }}
									</button>
								</div>
								<div class="flex flex-col">
									<p class="font-bold mb-1">Not running jobs</p>
									<button
										@click="toggleJob('NON_RUNNING_JOBS_REPORT','Non running jobs report')"
										:class="jobStatuses['NON_RUNNING_JOBS_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{ jobStatuses['NON_RUNNING_JOBS_REPORT'].status ? "Running" : "Not Running" }}
									</button>
								</div>
								<div class="flex flex-col">
									<p class="font-bold mb-1">Archive false</p>
									<button
										@click="toggleJob('ARCHIVE_FALSE_REPORT','Archive false report')"
										:class="jobStatuses['ARCHIVE_FALSE_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{ jobStatuses['ARCHIVE_FALSE_REPORT'].status ? "Running" : "Not Running" }}
									</button>
								</div>
								<div class="flex flex-col">
									<p class="font-bold mb-1">Held documents</p>
									<button
										@click="toggleJob('DOCUMENTS_HELD_REPORT','documents held report')"
										:class="jobStatuses['DOCUMENTS_HELD_REPORT'].status ? 'running' : 'not-running'"
										class="btn btn-sm w-24 mr-1 mb-2"
									>
										{{ jobStatuses['DOCUMENTS_HELD_REPORT'].status ? "Running" : "Not Running" }}
									</button>
								</div>
							</div>
							<div class=" text-left lg:text-left p-5  border-t border-gray-200 dark:border-dark-5">
								<p class="font-bold mb-1">Mandate choices:</p>
								<div class="mandate-choices">
									<div v-for="(item, index) in this.sgJobStatuses['MANDATE_CHOICE_REPORT'].data" :key="index">
										<button
											@click="toggleMandateChoiceJob(item.supplierGroupId, item.started , 'MANDATE_CHOICE_REPORT',)"
											:class="item.started ? 'running' : 'not-running'"
											class="btn btn-sm w-24 mr-1 mb-2"
										>
											{{ item.supplierGroupId }}
										</button>
									</div>
								</div>
								<div class="border-t border-gray-200 dark:border-dark-5">
									<div class="toggle-container mt-2">
										<p class="font-bold mb-1">Start new mandate choices reporting:</p>
										<ToggleSlide
											class="w-min"
											@update-value="updateDisplayStartNewJob($event, 'MANDATE_CHOICE_REPORT')"
											id="displayMandateChoice"
											:key="displayStartNewJob['MANDATE_CHOICE_REPORT']"
											:bool="displayStartNewJob['MANDATE_CHOICE_REPORT']"
										/>
									</div>

									<div v-show="displayStartNewJob['MANDATE_CHOICE_REPORT']">
										<p class="mb-1">Select Supplier Group(s)</p>
										<DropdownSelectAll
											:class="{ 'border border-primary-3': sgInvalid }"
											@update-value="updateSupplierGroups($event, 'MANDATE_CHOICE_REPORT')"
											:data="supplierGroups"
										/>
										<span v-if="errors['MANDATE_CHOICE_REPORT']" class="text-primary-3">At least 1 suppliergroup required</span>
										<div class="button-container">
											<button class="btn-bl btn btn-sm w-24 mr-2 mb-2 nymus-blue"
													@click="startSgJob('MANDATE_CHOICE_REPORT')">
												Start
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--Schedules-->
					<div class="box w-1/3 mr-2  mt-2">
						<!--GOOGLE DATA STUDIO-->
						<div>
							<!--Peppol schedules-->
							<div>
								<div class="flex items-start px-5 pt-5">
									<h2 class="font-medium text-base mr-auto">
										PEPPOL SCHEDULES
									</h2>
								</div>
								<div class="text-left  lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
									<div class="other-schedules-container">
										<div class="flex flex-col">
											<p class="font-bold mb-1">Transaction stat report</p>
											<button
												@click="toggleJob( 'PEPPOL_TSR','PEPPOL directory import schedule')"
												:class="jobStatuses['PEPPOL_TSR'].status  ? 'running' : 'not-running'"
												class="btn btn-sm w-24 mr-1 mb-2"
											>
												{{ jobStatuses['PEPPOL_TSR'].status ? "Running" : "Not Running" }}
											</button>
										</div>

										<div class="flex flex-col">
											<p class="font-bold mb-1">End user stat report</p>
											<button
												@click="toggleJob( 'PEPPOL_EUSR','PEPPOL directory import schedule')"
												:class="jobStatuses['PEPPOL_EUSR'].status  ? 'running' : 'not-running'"
												class="btn btn-sm w-24 mr-1 mb-2"
											>
												{{ jobStatuses['PEPPOL_EUSR'].status ? "Running" : "Not Running" }}
											</button>
										</div>
										<div class="flex flex-col">
											<p class="font-bold mb-1">Ingestion</p>
											<button
												@click="toggleJob( 'INGESTION_PEPPOL','PEPPOL directory import schedule')"
												:class="jobStatuses['INGESTION_PEPPOL'].status  ? 'running' : 'not-running'"
												class="btn btn-sm w-24 mr-1 mb-2"
											>
												{{ jobStatuses['INGESTION_PEPPOL'].status ? "Running" : "Not Running" }}
											</button>
										</div>
									</div>
								</div>
							</div>
							<!--OtherSchedules-->
							<div>
								<div class="flex items-start px-5 pt-5">
									<h2 class="font-medium text-base mr-auto">
										OTHER SCHEDULES
									</h2>
								</div>
								<div class="text-left  lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
									<!--SCHEDULER-->
									<div class="other-schedules-container">
										<div class="flex flex-col">
											<p class="font-bold mb-1">Google Data</p>
											<p class="font-bold mb-1">Studio</p>
											<button
												@click="toggleJob( 'DATA_STUDIO_REPORT_GENERATION','Google Data Studio schedule')"
												:class="jobStatuses['DATA_STUDIO_REPORT_GENERATION'].status  ? 'running' : 'not-running'"
												class="btn btn-sm w-24 mr-1 mb-2"
											>
												{{
													jobStatuses['DATA_STUDIO_REPORT_GENERATION'].status ? "Running" : "Not Running"
												}}
											</button>
										</div>
										<div class="flex flex-col">
											<p class="font-bold mb-1">PostDrop ingestion</p>
											<button
												@click=" toggleJob('POST_DROP_INGESTION','PostDrop ingestion')"
												:class="jobStatuses['POST_DROP_INGESTION'].status ? 'running' : 'not-running'"
												class="btn btn-sm w-24 mr-1 mb-2"
											>
												{{
													jobStatuses['POST_DROP_INGESTION'].status ? "Running" : "Not Running"
												}}
											</button>
										</div>
									</div>

									<div class="flex flex-col pt-5 pb-5 border-t border-gray-200 dark:border-dark-5">
										<p class="font-bold mb-1">Mandate onboarding reminders:</p>
										<div class="mandate-choices">
											<div
												v-for="(item, index) in this.sgJobStatuses['MANDATE_ONBOARDING_REMINDERS'].data"
												:key="index">
												<button
													@click="toggleMandateChoiceJob(item.supplierGroupId, item.started , 'MANDATE_ONBOARDING_REMINDERS',)"
													:class="item.started ? 'running' : 'not-running'"
													class="btn btn-sm w-24 mr-1 mb-2"
												>
													{{ item.supplierGroupId }}
												</button>
											</div>
										</div>
										<div class="border-t border-gray-200 dark:border-dark-5">
											<div class="toggle-container mt-2">
												<p class="font-bold mb-1">Start new mandate onboarding reminder:</p>
												<ToggleSlide
													class="w-min"
													@update-value="updateDisplayStartNewJob($event, 'MANDATE_ONBOARDING_REMINDERS')"
													id="displayMnadateOnboarding"
													:key="displayStartNewJob['MANDATE_ONBOARDING_REMINDERS']"
													:bool="displayStartNewJob['MANDATE_ONBOARDING_REMINDERS']"
												/>
											</div>

											<div v-show="displayStartNewJob['MANDATE_ONBOARDING_REMINDERS']">
												<p class="mb-1">
													Select Supplier Group(s)
												</p>
												<DropdownSelectAll
													:class="{ 'border border-primary-3': sgInvalid }"
													@update-value="updateSupplierGroups($event, 'MANDATE_ONBOARDING_REMINDERS')"
													:data="supplierGroups"
												/>
												<span
													v-if="errors['MANDATE_ONBOARDING_REMINDERS']"
													class="text-primary-3"
												>
													At least 1 suppliergroup required
												</span>
												<div class="button-container">
													<button class="btn-bl btn btn-sm w-24 mr-2 mb-2 nymus-blue"
															@click="startSgJob('MANDATE_ONBOARDING_REMINDERS')">
														Start
													</button>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="box w-1/3 mt-2 ">
						<div class="flex items-start px-5 pt-5">
							<h2 class="font-medium text-base mr-auto">
								ACTION TAB
							</h2>
						</div>
						<div class=" text-left lg:text-left p-5 border-t border-gray-200  dark:border-dark-5">
							<div>
								<div class="action-tab">
									<div>
										<p class="font-bold mb-1">Clear all orphaned messages</p>
										<div class="flex flex-row">
											<button
												@click="clearOrphanedMessages('clearOrphanedAll')"
												class="btn-bl btn btn-sm w-24 mr-2 mb-2 nymus-blue"
												:class="{ Clicked: bools.clearOrphanedAll }"
											>
												Start
											</button>
										</div>
									</div>
									<div>
										<p class="font-bold mb-1">Restore all users</p>
										<div>
											<button
												@click="restoreUsers('restoreAllUsers')"
												class="btn-bl btn btn-sm w-24 mr-1 mb-2 nymus-blue"
												:class="{ Clicked: bools.restoreAllUsers }"
											>
												Start
											</button>
										</div>
									</div>
								</div>
								<div class="clear-cache">
									<div v-for="item in cacheList" :key="item">
										<p class="font-bold mb-1">Clear {{ item }}</p>
										<div>
											<button
												@click="clearCash(item)"
												class="btn-bl btn btn-sm w-24 mr-1 mb-2 nymus-blue"
											>
												Start
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex flex-row">
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {computed, ref, watch, onMounted, onBeforeUnmount} from "vue";
import FileUpload from "../components/FileUpload.vue";
import {useStore} from "vuex";
import DropdownSelectAll from "../components/DropdownSelectAll.vue";
import ToggleSlide from "../components/ToggleSlide.vue";

export default {
	name: "Global Overview",
	components: {
		FileUpload,
		DropdownSelectAll,
		ToggleSlide
	},
	setup() {
		//booleans
		const store = useStore();
		const jobs = ref(store.state.schedule.jobs);
		const sgPrint = ref(
			store.state.supplierGroups.filter(
				(sg) => sg.channelsConfig.print !== undefined
			)
		);
		const defaultJobs = ref(
			jobs.value.filter((job) => job.supplierGroupId === "default")
		);
		const boolGoogleSchedule = computed(() => {
			if ((defaultJobs.value.find((dj) => dj.jobType === "DATA_STUDIO_REPORT_GENERATION")) !== undefined) {
				return defaultJobs.value.find((dj) => dj.jobType === "DATA_STUDIO_REPORT_GENERATION").started
			}
			return false
		});
		const boolScheduleSPEOS = computed(() => {
			let defaultJobs = store.state.schedule.jobs.filter((job) => job.supplierGroupId === "default")
			if ((defaultJobs.find((dj) => dj.jobType === "PRINT_SPEOS")) !== undefined) {
				return defaultJobs.find((dj) => dj.jobType === "PRINT_SPEOS").started
			}
			return false
		});
		const boolFeedbackScheduleSPEOS = computed(() => {
			let defaultJobs = store.state.schedule.jobs.filter((job) => job.supplierGroupId === "default")
			if ((defaultJobs.find((dj) => dj.jobType === "PRINT_FEEDBACK_SPEOS")) !== undefined) {
				return defaultJobs.find((dj) => dj.jobType === "PRINT_FEEDBACK_SPEOS").started
			}
			return false
		});
		const boolFeedbackScheduleEASYPOST = computed(() => {
			let defaultJobs = store.state.schedule.jobs.filter((job) => job.supplierGroupId === "default")
			if ((defaultJobs.find((dj) => dj.jobType === "EP_FEEDBACK")) !== undefined) {
				return defaultJobs.find((dj) => dj.jobType === "EP_FEEDBACK").started
			}
			return false
		});
		const boolMailErrorSchedule = computed(() => {
			if ((defaultJobs.value.find((dj) => dj.jobType === "MAIL_ERROR_REPORT")) !== undefined) {
				return defaultJobs.value.find((dj) => dj.jobType === "MAIL_ERROR_REPORT").started
			}
			return false
		});
		const boolChannelDetailReport = computed(() => {
			if ((defaultJobs.value.find((dj) => dj.jobType === "CHANNEL_DETAIL_REPORT")) !== undefined) {
				return defaultJobs.value.find((dj) => dj.jobType === "CHANNEL_DETAIL_REPORT").started
			}
			return false
		});

		const boolTransactionOverviewReport = computed(() => {
			if ((defaultJobs.value.find((dj) => dj.jobType === "TRANSACTION_OVERVIEW_REPORT")) !== undefined) {
				return defaultJobs.value.find((dj) => dj.jobType === "TRANSACTION_OVERVIEW_REPORT").started
			}
			return false
		});

		const boolChannelChoiceReport = computed(() => {
			if ((defaultJobs.value.find((dj) => dj.jobType === "CHANNEL_CHOICE_REPORTT")) !== undefined) {
				return defaultJobs.value.find((dj) => dj.jobType === "CHANNEL_CHOICE_REPORT").started
			}
			return false
		});
		const boolPostDropErrorReport = computed(() => {
			if ((defaultJobs.value.find((dj) => dj.jobType === "POST_DROP_ERROR_REPORT")) !== undefined) {
				return defaultJobs.value.find((dj) => dj.jobType === "POST_DROP_ERROR_REPORT").started
			}
			return false
		});

		const boolSgSpecificErrorsReport = computed(() => {
			if ((defaultJobs.value.find((dj) => dj.jobType === "SG_SPECIFIC_ERRORS_REPORT")) !== undefined) {
				return defaultJobs.value.find((dj) => dj.jobType === "SG_SPECIFIC_ERRORS_REPORT").started
			}
			return false
		});
		const boolUblStatusReport = computed(() => {
			if ((defaultJobs.value.find((dj) => dj.jobType === "UBL_STATUS_REPORT")) !== undefined) {
				return defaultJobs.value.find((dj) => dj.jobType === "UBL_STATUS_REPORT").started
			}
			return false
		});
		const boolPostDropIngestion = computed(() => {
			if ((defaultJobs.value.find((dj) => dj.jobType === "POST_DROP_INGESTION")) !== undefined) {
				return defaultJobs.value.find((dj) => dj.jobType === "POST_DROP_INGESTION").started
			}
			return false
		});
		const boolMandateOverviewReport = computed(() => {
			if ((defaultJobs.value.find((dj) => dj.jobType === "MANDATE_OVERVIEW_REPORT")) !== undefined) {
				return defaultJobs.value.find((dj) => dj.jobType === "MANDATE_OVERVIEW_REPORT").started
			}
			return false
		});

		let boolNonRunningJobsReport = computed(() => {
			if (jobs.value.find((dj) => dj.jobType === "NON_RUNNING_JOBS_REPORT")) {
				return jobs.value.find((dj) => dj.jobType === "NON_RUNNING_JOBS_REPORT").started
			}
			return false
		})
		let boolArchiveFalseReport = computed(() => {
			if (jobs.value.find((dj) => dj.jobType === "ARCHIVE_FALSE_REPORT")) {
				return jobs.value.find((dj) => dj.jobType === "ARCHIVE_FALSE_REPORT").started
			}
			return false
		})

		const bools = ref({});
		const reprocessValue = ref("");
		let nonRunningJobStatus = ref(false);

		watch(() => store.state.schedule, () => {
			if (jobs.value.find((dj) => dj.jobType === "NON_RUNNING_JOBS_REPORT")) {
				nonRunningJobStatus.value = jobs.value.find((dj) => dj.jobType === "NON_RUNNING_JOBS_REPORT").started
			} else nonRunningJobStatus.value = false
		})

		onMounted(() => {
			if (store.state.schedule.jobs === undefined) {
				store.dispatch("schedule/fetchSchedule")
			}
			store.commit('fileUploaded', false)
		})

		onBeforeUnmount(() => {
			if (store.state.schedule.jobs === undefined) {
				store.dispatch("schedule/fetchSchedule")
			}
			store.commit('fileUploaded', false)
		})

		return {
			store,
			jobs,
			sgPrint,
			defaultJobs,
			boolGoogleSchedule,
			boolScheduleSPEOS,
			boolFeedbackScheduleSPEOS,
			boolFeedbackScheduleEASYPOST,
			boolMailErrorSchedule,
			boolChannelDetailReport,
			boolTransactionOverviewReport,
			boolNonRunningJobsReport,
			boolArchiveFalseReport,
			boolMandateOverviewReport,
			reprocessValue,
			bools,
		};
	},
	data() {
		return {
			supplierGroups: this.$store.state.supplierGroups,
			jobStatuses: {
				NON_RUNNING_JOBS_REPORT: {
					status: false,
				},
				ARCHIVE_FALSE_REPORT: {
					status: false,
				},
				TRANSACTION_OVERVIEW_REPORT: {
					status: false,
				},
				CHANNEL_CHOICE_REPORT: {
					status: false,
				},
				POST_DROP_ERROR_REPORT: {
					status: false,
				},
				SG_SPECIFIC_ERRORS_REPORT: {
					status: false,
				},
				UBL_STATUS_REPORT: {
					status: false,
				},
				POST_DROP_INGESTION: {
					status: false,
				},
				MANDATE_OVERVIEW_REPORT: {
					status: false,
				},
				CHANNEL_DETAIL_REPORT: {
					status: false,
				},
				CHANNEL_ARCHIVED_DETAIL_REPORT: {
					status: false,
				},
				MAIL_ERROR_REPORT: {
					status: false,
				},
				MANDATE_CHOICE_REPORT: {
					status: false,
				},
				DATA_STUDIO_REPORT_GENERATION: {
					status: false,
				},
				PEPPOL_DIRECTORY_IMPORT: {
					status: false,
				},
				INGESTION_PEPPOL: {
					status: false,
				},
				PEPPOL_EUSR: {
					status: false,
				},
				PEPPOL_TSR: {
					status: false,
				},
				DOCUMENTS_HELD_REPORT: {
					status: false,
				}
			},

			sgJobStatuses: {
				MANDATE_CHOICE_REPORT: {
					status: false,
					data: []
				},
				MANDATE_ONBOARDING_REMINDERS: {
					status: false,
					data: []
				}
			},
			errors: {
				MANDATE_CHOICE_REPORT: false,
				MANDATE_ONBOARDING_REMINDERS: false
			},

			selectedSupplierGroup: {
				MANDATE_CHOICE_REPORT: []
			},
			displayStartNewJob: {
				MANDATE_CHOICE_REPORT: false,
				MANDATE_ONBOARDING_REMINDERS: false
			},
			cacheList: [
				"user-cache",
				"suppliergroup-cache",
				"idp-cache",
				"doc-cache",
				"host-cache",
				"receiver-cache"
			],
			fileData: '',
		}
	},
	mounted() {
		this.setJobStatuses();
		this.setMandateChoiceStatuses();
	},
	computed: {
		globalJobs() {
			return this.$store.state.schedule.jobs
		},

		fileUploaded() {
			return this.store.state.fileUploaded
		}
	},
	watch: {
		fileData(newVal) {
			if (newVal) {
				this.reprocessSpeosFile(this.fileData);
				this.$store.commit('fileUploaded', false)
			}
		},

		globalJobs() {
			this.setJobStatuses();
			this.setMandateChoiceStatuses();
		}
	},


	methods: {
		triggerPeppollIngestion() {
			this.$store.dispatch("peppol/triggerPeppolIngestion")
		},
		triggerPostDropIngestion() {
			this.$store.dispatch("globalActions/triggerPostDropIngestion")
		},
		updateDisplayStartNewJob(data, jobType) {
			this.displayStartNewJob[jobType] = data;
		},
		startSgJob(jobType) {
			if (!this.selectedSupplierGroup[jobType].length) {
				this.errors[jobType] = true;
				return
			}
			this.selectedSupplierGroup[jobType].forEach(selectedSgId => {
				this.toggleMandateChoiceJob(selectedSgId, false, jobType);
			})
		},
		updateSupplierGroups(data, jobType) {
			this.errors[jobType] = false
			let foundArr = [];
			for (let idx of data) {
				let found = this.supplierGroups.at(idx).supplierGroupId;
				foundArr.push(found);
			}
			this.selectedSupplierGroup[jobType] = foundArr;
		},
		toggleMandateChoiceJob(sgId, status, reportType) {
			let payload = {
				status: status,
				reportType: reportType,
				sgId: sgId
			}
			this.$store.dispatch('schedule/toggleSgJob', payload)
		},
		setMandateChoiceStatuses() {
			for (let sgJob in this.sgJobStatuses) {
				let mandateChoiceData = [];
				this.$store.state.schedule.jobs.forEach(job => {
					if (job.jobType === sgJob) {

						mandateChoiceData.push(job)
					}
				})
				this.sgJobStatuses[sgJob].data = mandateChoiceData;
			}
		},
		setJobStatuses() {
			for (let printJob in this.jobStatuses) {
				this.$store.state.schedule.jobs.forEach(job => {
					if (this.$store.state.schedule.jobs.find((dj) => dj.jobType === printJob)) {
						if (job.jobType === printJob) {
							this.jobStatuses[printJob].status = job.started
						}
					}
				})
			}
		},

		setData(data) {
			this.fileData = data
		},

		reprocessSpeosFile(data) {
			this.reprocessValue = data.value;
			let payload = {
				body: {
					bucketName: "",
					fileName: "",
				},
			};
			if (window.location.hostname.includes("localhost") || window.location.hostname.includes("staging")) payload.body.bucketName = "optipost-printing-staging";
			else payload.body.bucketName = "optipost-printing-production"

			payload.body.fileName = "manualfeedback/" + data.value;
			this.$store.dispatch("schedule/printSpeosFeedbackReprocess", payload);
			this.getAlert("Feedback reprocessed", 'Succesfully')
		},

		toggleJob(jobType, alertText) {
			let isStarted = false;
			if (this.$store.state.schedule.jobs.find((dj) => dj.jobType === jobType)) {
				isStarted = this.$store.state.schedule.jobs.find((dj) => dj.jobType === jobType).started
			} else {
				isStarted = false
			}

			if (isStarted) {
				this.$store.dispatch("schedule/stopGlobalJobs", jobType);
				this.getAlert(alertText, 'Stopped');

			} else if (!isStarted) {
				this.$store.dispatch("schedule/startGlobalJobs", jobType);
				this.getAlert(alertText, 'Started');
			}
		},
		setJobStatus(jobType, status) {
			this.jobStatuses[jobType].status = status;
		},

		async toggleGlobalJob(jobType, alertText) {
			let isStarted = await this.getStartedFromJobType(jobType);
			if (isStarted) {
				await this.$store.dispatch("schedule/stopGlobalJobs", jobType);
				this.getAlert(alertText, 'Stopped')
				this.changeBool(jobType);
			} else if (!isStarted) {
				await this.$store.dispatch("schedule/startGlobalJobs", jobType);
				this.getAlert(alertText, 'Started')
				this.changeBool(jobType);
			}
		},
		async getStartedFromJobType(jobType) {
			await this.$store.dispatch("schedule/fetchSchedule");
			this.jobs.value = this.store.state.schedule.jobs;
			this.defaultJobs.value = this.jobs.value.filter(
				(job) => job.supplierGroupId === "default"
			)

			let started = false;
			if (this.defaultJobs.value.find((dj) => dj.jobType === jobType)) {
				started = this.defaultJobs.value.find((dj) => dj.jobType === jobType).started
			}

			return started;
		},
		async changeBool(jobType) {
			let bool = await this.getStartedFromJobType(jobType);
			switch (jobType) {
				case "PRINT_SPEOS":
					this.boolScheduleSPEOS = bool;
					break;
				case "PRINT_FEEDBACK_SPEOS":
					this.boolFeedbackScheduleSPEOS = bool;
					break;
				case "MAIL_ERROR_REPORT":
					this.boolMailErrorSchedule = bool;
					break;
				case "CHANNEL_DETAIL_REPORT":
					this.boolChannelDetailReport = bool;
					break;
				case "TRANSACTION_OVERVIEW_REPORT":
					this.boolTransactionOverviewReport = bool;
					break;
				case "CHANNEL_CHOICE_REPORT":
					this.boolChannelChoiceReport = bool;
					break;
				case "POST_DROP_ERROR_REPORT":
					this.boolPostDropErrorReport = bool;
					break;
				case "SG_SPECIFIC_ERRORS_REPORT":
					this.boolSgSpecificErrorsReport = bool;
					break;
				case "UBL_STATUS_REPORT":
					this.boolUblStatusReport = bool;
					break;

				case "POST_DROP_INGESTION":
					this.boolPostDropIngestion = bool;
					break;

				case "NON_RUNNING_JOBS_REPORT":
					this.boolNonRunningJobsReport = bool;
					break;
				case "ARCHIVE_FALSE_REPORT":
					this.boolArchiveFalseReport = bool;
					break;
				case "MANDATE_OVERVIEW_REPORT":
					this.boolMandateOverviewReport = bool;
					break;
				case "DATA_STUDIO_REPORT_GENERATION":
					this.boolGoogleSchedule = bool;
					break;
			}
		},

		clearCash(cashType) {
			this.$store.dispatch("globalActions/clearCash", cashType);
		},
		restoreUsers(id) {
			this.clickEvent(id);
			this.$store.dispatch("globalActions/RestoreAllUsers");
			this.getAlert("Restored All users", "Succesfully");
		},
		clearOrphanedMessages(id) {
			this.clickEvent(id);
			this.$store.dispatch("globalActions/ClearAllOrphanedMessages");
			this.getAlert("Cleared all messages", "Succesfully");
		},
		speosEvents(isFor) {
			switch (isFor) {
				case "printSpeos": {
					this.$store.dispatch("schedule/printSpeosSchedule");
					this.getAlert('Print SPEOS', 'Started')
					break;
				}
				case "printSpeosFeedback": {
					this.$store.dispatch("schedule/printSpeosFeedback");
					this.getAlert('Print SPEOS feedback', 'Started')
					break;
				}
				default:
					break;
			}
			this.clickEvent(isFor);
		},
		triggerEasypostFeedback() {
			this.$store.dispatch("schedule/easypostFeedback")
			this.getAlert("Easypost feedback", 'started')
		},
		ingestionScheduleAll(event) {
			if (event.target.innerText === "Start") {
				for (let sg of this.$store.state.supplierGroups) {
					this.$store.dispatch("schedule/startIngestionSchedule", sg.supplierGroupId);
				}
				this.getAlert('All Ingestion Schedules', 'Started')

			} else if (event.target.innerText === "Stop") {
				for (let sg of this.$store.state.supplierGroups) {
					this.$store.dispatch("schedule/stopIngestionSchedule", sg.supplierGroupId);
				}
				this.getAlert('All Ingestion Schedules', 'Stopped')
			}
			this.clickEvent(event.target.id);
		},
		twikeyMandateSchedule(isStarted, id) {
			if (isStarted) {
				this.$store.dispatch('schedule/twikeyMandateSchedule', 'POST')
				this.getAlert('Twikey mandate scheduler', 'Started')
			} else {
				this.$store.dispatch('schedule/twikeyMandateSchedule', 'DELETE')
				this.getAlert('Twikey mandate scheduler', 'Stopped')
			}
			this.clickEvent(id);
		},
		twikeyTranactionSchedule(isStarted, id) {
			if (isStarted) {
				this.$store.dispatch('schedule/twikeyTransactionSchedule', 'POST')
				this.getAlert('Twikey transaction scheduler', 'Started')
			} else {
				this.$store.dispatch('schedule/twikeyTransactionSchedule', 'DELETE')
				this.getAlert('Twikey transaction scheduler', 'Stopped')
			}
			this.clickEvent(id);
		},
		twikeyFeedPolling(isStarted, id) {
			if (isStarted) {
				this.$store.dispatch('schedule/twikeyInvoiceFeedPolling', 'POST')
				this.getAlert('Twikey invoice feed polling', 'Started')
			} else {
				this.$store.dispatch('schedule/twikeyInvoiceFeedPolling', 'DELETE')
				this.getAlert('Twikey invoice feed polling', 'Stopped')
			}
			this.clickEvent(id);
		},
		clickEvent(id) {
			if (!this.bools[id]) {
				this.bools[id] = false;
			}
			this.bools[id] = true;
			setTimeout(() => {
				this.bools[id] = false;
			}, 2000);
		},
		getAlert(title, text) {
			if (this.$store.state.responseStatus === 200) {
				this.$store.commit("toggleAlert", {
					title: title,
					text: text,
				});
			} else {
				this.$store.commit("toggleAlert", {
					title: title,
					text: "ERROR",
				});
			}
		}
	},
};
</script>

<style scoped>
.running {
	background-color: #91c714;
	color: white;
}

.not-running {
	background-color: #d32929;
	color: white;
}

.btn-bl {
	background-color: #00adea;
	color: white;
}

.Clicked {
	background-color: lightgrey;
	pointer-events: none;
}

.reporting-schedule, .mandate-choices, .other-schedules-container {
	display: grid;
	grid-template-rows: repeat(3, auto);
	grid-template-columns: 50% 50%;
	grid-auto-flow: row;
}

.mandate-choices {
	grid-template-columns: repeat(4, 25%);
}

.button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
}

.toggle-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: auto auto;
	justify-items: start;
}

.clear-cache, .action-tab {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: repeat(3, auto);
	grid-auto-flow: row;
}
</style>
