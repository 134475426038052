<template>
	<div :class="{'brightness-95': this.$store.state.displayEditLeGeneral}" class="box col-span-6" v-if="legalEntity">
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">Negative creditnote: </label>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="creditNoteNegative"
							:bool="creditNoteNegative"
							:disabled="true"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">Add zero tax segments: </label>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="addZeroTaxSegments"
							:bool="addZeroTaxSegments"
							:disabled="true"
							:renew-bool="true"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

import ToggleSlide from "@/components/ToggleSlide.vue"

export default {
	name: "ViewIngestion",
	components: {ToggleSlide},
	data() {
		return {
			legalEntity: this.$store.state.activeLE,
			copyName: false,
			copyLe: false,
			copyBrandingId: false,
			creditNoteNegative: this.$store.state.activeLE.ingestionConfig?.creditNoteNegative ? this.$store.state.activeLE.ingestionConfig.creditNoteNegative : false,
			addZeroTaxSegments: this.$store.state.activeLE.ingestionConfig?.addZeroTaxSegments ? this.$store.state.activeLE.ingestionConfig.addZeroTaxSegments : false
		}
	},
	watch: {
		"$store.state.activeLE": {
			handler(data){
				this.legalEntity = data
				this.addZeroTaxSegments = data.ingestionConfig?.addZeroTaxSegments ? data.ingestionConfig?.addZeroTaxSegments : false
				this.creditNoteNegative = data.ingestionConfig?.creditNoteNegative ? data.ingestionConfig?.creditNoteNegative : false
			}
		}
	}
}
</script>