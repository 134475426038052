import {createStore} from 'vuex'
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import globalActionsModule from "./globalActions/index"
import peppolModule from "./peppol/index"
import exportModule from "./exporting/index"
import actionsScheduleModule from "./schedule/index"
import suppliergroupModule from "./suppliergroup/index"
import fileModule from "./file/index"
const env = window.environment;


const store = createStore({
    modules: {
        globalActions: globalActionsModule,
        peppol: peppolModule,
        exporting: exportModule,
        schedule: actionsScheduleModule,
        suppliergroup: suppliergroupModule,
        file: fileModule
    },
    state() {
        return {
            API: {
                updatePayment: 'api/admin/supplier-groups/:supplierGroupId/payment-settings',
                paymentJobs: 'api/admin/payment/jobs',
                continueIngestion: 'api/ingestion/process/:supplierGroupId/continue',
                deleteFromLegalArchive: 'api/admin/supplier-groups/:supplierGroupId/documents/delete',
                addPeppolIdentifier: 'api/admin/supplier-groups/:supplierGroupId/legal-entities/:legalEntityNumber/peppol-identifiers',
                retrievePeppolInfo: 'api/admin/peppol/smp/participant/:identifier',
                getHermesMigrationKey: 'api/admin/peppol/hermes/migration-key/:identifier',
                migrateHermesToPeppol: 'api/admin/peppol/smp/migrate',
                createPeppolParticipant: 'api/admin/peppol/smp/participant',
                createPeppolDocument: 'api/admin/peppol/smp/document',
                deletePeppolDocument: 'api/admin/peppol/smp/document',
                deletePeppolParticipant: 'api/admin/peppol/smp/participant',
                peppolIngestion: "api/admin/ingestion/process-peppol",
                triggerPeppolIngestion: "api/ingestion/process-peppol",
                triggerPostDropIngestion: "api/admin/post-drop/ingestion",
                editBillingInfo: 'api/admin/supplier-groups/:supplierGroupId/legal-entities/:legalEntityNumber/billing-info',
                smpParticipants: "api/admin/peppol/smp/participants",
                uploadFile: "api/admin/supplier-groups/upload/files",
                reprocessXml : "api/admin/reprocess-xml",
                updateLegalEntityIngestionSettings: 'api/admin/supplier-groups/:supplierGroupId/legal-entities/:legalEntityNumber/ingestion-settings',
                deleteLegalEntity: 'api/admin/supplier-groups/:supplierGroupId/legal-entities/:legalEntityNumber',
                keycloakToken: env.data.keycloakUrl + '/realms/' + env.data.keycloakRealm + '/protocol/openid-connect/token',
                pauseApiIngestion: 'api/admin/supplier-groups/:supplierGroupId/pause-api',

                peppolConfigSg: "api/admin/supplier-groups/:supplierGroupId/peppol-config",
                peppolConfigLe: "api/admin/supplier-groups/:supplierGroupId/legal-entities/:legalEntityNumber/peppol-config",

                massResendPostmark: "api/admin/documents/batch-send/emails",

                pingRaspberryOffice: 'api/admin/reader/office',
                pingRaspberrySven: 'api/admin/reader/sven',

                addEnvironment: 'api/admin/supplier-groups/:supplierGroupId/environment'
            },

            token: '',
            refreshToken: '',
            displayShowHtml: false,
            displayEditGeneral: false,
            displayEditPeppolDocuments: false,
            displayEditPayments: false,
            displayEditBranding: false,
            displayEditIngestion: false,
            displayEditExtraction: false,
            displayAddExtractionTemplate: false,
            displayEditSettings: false,
            displayEditPass: false,
            displayEditContactInfoAndHelp: false,
            displayEditEDI: false,
            displayEditMailChannel: false,
            displayEditPrint: false,
            displayEditReporting: false,

            displayEditMailTemplate: false,
            displayEditQRTemplate: false,
            displayEditOverlayTemplate: false,
            displayAddOverlayTemplate: false,
            displayAddMailTemplate: false,
            displayAddPreingestionTemplate: false,
            displayAddResendTemplate: false,
            displayAddPreingestionTemplate1: false,

            displayEditTacTemplate: false,
            displayEditInsertsTemplate: false,
            displayEditPreIngestion: false,
            displayEditResendTemplate: false,
            displayEditChannelMaintenance: false,
            displayEditPeppol: false,

            displayEditLeGeneral: false,
            displayEditLePayment: false,
            displayEditLeBankAccounts: false,
            displayEditLePeppolIdentifiers: false,
            displayEditBillingInfo: false,
            displayModifyPeppolDocument: false,

            displayAddExternalPortalLink: false,
            displayAddXsd: false,
            displayAddExtractionField: false,
            displayAddExtractionField2: false,
            displayAddExtractionField3: false,
            displayAddExtractionField4: false,
            displayAddExtractionField5: false,
            displayAddExtractionField6: false,
            displayAddReceiverMail: false,
            displayAddEmail: false,
            displayAddEdi: false,
            displayAddPrintJob: false,
            displayAddManual: false,
            displayAddQrText: false,
            displayAddQrImage: false,
            displayAddOverlayFile: false,
            displayAddOverlayFile1: false,
            displayAddLinkFontFile: false,

            displayAddInsertsTemplate: false,
            displayAddInsertsFile: false,
            displayAddLeBankAccount: false,
            displayAddSupplierGroup: false,
            displayAddLegalEntity: false,
            displayAddQrTemplate: false,
            displayAddQrImage1: false,
            displayAddPreingestionFile: false,
            displayAddPreingestionJsonFile: false,
            displayAddPreingestionJsonFile1: false,
            displayAddResendTemplateFile: false,
            displayAddResendTemplateFile1: false,

            allQrTemplateDeleted: false,


            viewHtmlContent: "",
            parentTacFile: "",
            parentInsertsFile: "",
            addedEditTacFile: {},
            addedEditInsertsFile: {},
            addedTemplateTacFile: {},
            addedTemplateInsertsFile: {},
            filterSG: "",
            filterSchedule: "",
            supplierGroups: [],
            activeSG: {},
            activeLE: {},
            activeSGTab: "General",
            printJobs: [],
            xsdFiles: [],
            ediFiles: {},
            tacFiles: [],
            tacFilesLE: [],
            insertFiles: [],
            insertFilesLE: [],
            receiverMail: {},
            receiverMailLE: {},
            bankAccounts: [],

            schedule: {},
            response: {},
            responseStatus: 0,
            tempFile: "",
            tempFileName: '',
            baseUrl: '',
            baseUrlNymusX: '',
            schedulerUrl: 'api/scheduler/jobs',
            supplierGroupUrl: 'api/admin/supplier-groups',
            legalEntityUrl: 'api/admin/legal-entities/search',
            alert: {},
            csvResponse: '',
            ibanRegex: /^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$/,
            emailRegex: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/ /* /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ */,
            urlRegex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/ /* /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g */,
            phoneNumberRegex: /^['+']?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/,
            //dummy trigger
            selectAllbool: false,
            //Status chain Create SG
            currentUser: {},
            statusCreateSG: 0,
            statusCreateRealm: 0,
            statusCreateClient: 0,
            statusCreateBuckets: 0,
            statusCreateFTP: 0,
            qrBoundary: {width: 300, height: 426},
            bucketName: "",
            qrImageSize: {
                width: 0,
                height: 0
            },
            errorAlert: {
                display: false,
                text: 'Sorry, something went wrong. Try again later.'
            },
            extractionTemplates: [],
            extractionTemplateIds: [],
            cancelEditExtraction: false,
            uploadedFileUrl: '',
            legalEntityCreated: false,
            sgOverviewPageNumber: 1,
            selectedExtractionTemplateName: '',
            selectedQrTemplateName: '',
            selectedOverlayTemplateName: '',
            selectedPreIngestionTemplateName: '',
            selectedResendTemplateName: '',
            selectedMailTemplateName: '',

            overlayTemplateDeleted: false,

            ediReceivingProviderData: [
                {id: 0, name: "Arco"},
                {id: 1, name: "Ariba"},
                {id: 3, name: "Basware"},
                {id: 4, name: "ChorusPro"},
                {id: 5, name: "Coupa"},
                {id: 6, name: "Eks"},
                {id: 7, name: "Mercurius"},
                {id: 8, name: "OpenPeppol"},
                {id: 9, name: "OpenPeppolGLN"},
                {id: 10, name: "Peppol"},
                {id: 11, name: "TradeShift"},
                {id: 12, name: "Tungsten"},
            ],
            sendingProvider: [
                {id: 0, name: "ADVALVAS"},
                {id: 1, name: "BABELWAY"},
                {id: 2, name: "BASWARE"},
                {id: 3, name: "NYMUS_PEPPOL"},
            ],
            docTypes: [
                {id: 0, name: "CAR_INSURANCE"},
                {id: 1, name: "CREDIT_NOTE"},
                {id: 2, name: "DUNNING"},
                {id: 3, name: "FISCAL"},
                {id: 4, name: "GENERIC"},
                {id: 5, name: "INVOICE"},
                {id: 6, name: "MANDATE_ONBOARDING"},
                {id: 7, name: "SALARY"},
                {id: 8, name: "YEARLY"},
            ],
            templateReason: [
                {id: 1, name: "CHANNEL_ONBOARDING"},
                {id: 2, name: "ONBOARDING_MANDATES"},
                {id: 3, name: "PAYMENT"},
            ],
            offsetData: [
                {id: 0, name: 1},
                {id: 1, name: 2},
                {id: 2, name: -1},
                {id: 2, name: -2},

            ],
            overlayData: [
                {id: 0, name: "FIRST_PAGE_ONLY"},
                {id: 1, name: "LAST_PAGE_ONLY"},
                {id: 2, name: "OFFSET"}
            ],
            overlayMode: [
                {id: 0, name: "ALL_PAGES"},
                {id: 1, name: "FIRST_PAGE_ONLY"},
                {id: 2, name: "LAST_PAGE_ONLY"},
                {id: 3, name: "EVEN_PAGES_ONLY"},
                {id: 4, name: "ODD_PAGES_ONLY"},
            ],
            linkField: [
                {id: 1, name: "CHANNEL_CHOICE_ACCESS_CODE"},
                {id: 0, name: "MANDATE_ONBOARDING_ACCESS_CODE"},

            ],
            smpProviders: [
                {id: 0, name: "SMP_NYMUS"},
            ],
            documentTypeIdentifiers: [
                {id: 0, name: "BIS_BILLING_CREDIT_NOTE_V3"},
                {id: 1, name: "BIS_BILLING_INVOICE_V3"},
                {id: 2, name: "CATALOGUE_3_0"},
                {id: 3, name: "CATALOGUE_RESPONSE_3_0"},
                {id: 4, name: "DESPATCH_ADVICE_3_0"},
                {id: 5, name: "INVOICE_RESPONSE_3_0"},
                {id: 6, name: "MESSAGE_LEVEL_RESPONSE_3_0"},
                {id: 7, name: "ORDER_3_0"},
                {id: 8, name: "ORDER_AGREEMENT_3_0"},
                {id: 9, name: "ORDER_CANCELLATION"},
                {id: 10, name: "ORDER_CHANGE"},
                {id: 11, name: "ORDER_RESPONSE_3_0"},
                {id: 12, name: "ORDER_RESPONSE_ADVANCED"},
                {id: 13, name: "PUNCH_OUT_3_0"},
            ],
            peppolMessageType: [
                {id: 0, name: "All"},
                {id: 1, name: "Billing"},
                {id: 2, name: "Catalogue"},
                {id: 3, name: "CatalogueResponse"},
                {id: 4, name: "Order"},
                {id: 5, name: "DespatchAdvice"},
                {id: 6, name: "InvoiceResponse"},
                {id: 7, name: "MessageLevelResponse"},
                {id: 8, name: "OrderAgreement"},
                {id: 9, name: "OrderCancellation"},
                {id: 10, name: "OrderChange"},
                {id: 11, name: "OrderResponse"},
                {id: 12, name: "OrderResponseAdvanced"},
                {id: 13, name: "PunchOut"},
            ],
            peppolIdentifiers: {
                peppolInfoDisplay: false,
                displayCreateDocument: false,
                participantFound: false,
                participantNotFound: false,
                peppolRetrievedData: {
                    peppolIdentifier: '',
                    information: []
                },
                peppolItemChanged: false,
                peppolDocumentCreated: false,
                peppolDocumentDeleted : false,
            },
            hermesKey: '',
            displayLoader: false,
            tableFieldTypeData: [
                {id: 0, name: "TABLE_LINE_NET"},
                {id: 1, name: "TABLE_ITEM_DESCRIPTION"},
                {id: 2, name: "TABLE_LINE_VAT"},
                {id: 3, name: "TABLE_ITEM_QUANTITY"},
                {id: 4, name: "TABLE_ITEM_PRICE_PER_UNIT"},
                {id: 5, name: "TABLE_ITEM_REDUCTION"},
                {id: 6, name: "TABLE_ITEM_UNIT"},
                {id: 7, name: "TABLE_OTHER"},
            ],

            tableSummaryFieldTypeData: [
                {id: 0, name: "SUMMARY_TABLE_NET"},
                {id: 1, name: "SUMMARY_TABLE_VAT_PERCENTAGE"},
                {id: 3, name: "SUMMARY_TABLE_VAT_AMOUNT"},
                {id: 3, name: "SUMMARY_TABLE_OTHER"},
            ],

            addressesTypeData: [
                {id: 0, name: "SELECT TYPE"},
                {id: 1, name: "DELIVERY_ADDRESS"},
                {id: 2, name: "LEGAL_ADDRESS"},
                {id: 3, name: "POSTAL_ADDRESS"},

            ],
            overviewTableDataUpdated: false,

            smpParticipants: [],
            identifiersList: [],
            smpParticipantData: [],
            sgTableInitialPage: 1,
            peppolDataRetrieved: false,

            ingestionStatus: {},
            pauseApiIngestionChanged: {},
            startArchive: false,
            fileUploaded: false,

            sftpMountFileUploaded: {
                pdf: false,
                xml: false,
                attachment: false,
            },
        }
    },
    mutations: mutations,
    getters: getters,
    actions: actions,
})

export default store
