<template>
	<div style="display: flex" :style="{flexDirection: isExternalFTPChecked, marginTop: '15px'} ">
		<div class="w-2/5">
			<!--Peppol configuration-->
			<div style="display: flex; flex-direction: column">
				<div class="one-line" style="margin-bottom: 10px">
					<p class="font-bold mr-2">Peppol configuration:</p>
					<ToggleSlide
						class="w-min"
						:bool="displayPeppolConfig"
						:disabled=true
						:renew-bool="true"
					/>
				</div>
				<!--External FTP-->
				<div v-if="displayPeppolConfig" style="margin-left: 20px">
					<div class="one-line" style="margin-bottom: 10px">
						<p class="font-bold mr-2">External FTP:</p>
						<ToggleSlide
							class="w-min"
							:bool="peppolConfig.externalFTPConfig !== undefined"
							:disabled="true"
							:renew-bool="true"
						/>
					</div>
					<div v-if="peppolConfig.externalFTPConfig">
						<div class="ml-6">
							<div>
								<div style="margin-top: 5px; margin-bottom: 5px">
									<p class="font-bold">Peppol type:</p>
									<DropdownSelectAll
										v-model="peppolConfig.externalFTPConfig.peppolMessageTypes"
										:data="peppolTypeIdentifiers"
										:key="peppolConfig.externalFTPConfig.peppolMessageTypes"
										:selected="peppolConfig.externalFTPConfig.peppolMessageTypes"
										:disabled=true
										:noSelectAll="true"
									/>
								</div>
								<Field
									title="URL:"
									type="text"
									:disabled="true"
									v-model="peppolConfig.externalFTPConfig.externalFTP.url"
								/>
							</div>
							<Field
								title="Location:"
								type="text"
								v-model="peppolConfig.externalFTPConfig.externalFTP.location"
								:disabled="true"
							/>
							<Field
								title="User:"
								type="text"
								v-model="peppolConfig.externalFTPConfig.externalFTP.user"
								:disabled="true"
							/>
							<Field
								title="Password:"
								type="text"
								v-model="peppolConfig.externalFTPConfig.externalFTP.password"
								:disabled="true"
							/>
							<div>
								<p class="font-bold">FTP type:</p>
								<Dropdown
									:data="ftpTypeOptions"
									:selected="peppolConfig.externalFTPConfig.externalFTP.ftpType"
									:disabled=true
								/>
							</div>

							<Field
								title="Port:"
								type="number"
								v-model="peppolConfig.externalFTPConfig.externalFTP.port"
								:disabled=true
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			v-if="displayPeppolConfig"
			class="ml-24 w-2/5"
			style="display: flex; flex-direction: column;"
			:style="{marginTop: isExternalFTPChecked === 'column' ? '0px' : '31px' }"
		>
			<!-- basware-->
			<div class=" one-line-input">
				<div class="one-line">
					<p class="font-bold mr-2">Basware: </p>
					<ToggleSlide
						class="w-min"
						:bool="peppolConfig.baswareConfig !== undefined"
						:disabled="true"
						:renew-bool="true"
					/>
				</div>
				<div class="ml-6 w-full" v-if="peppolConfig.baswareConfig">
					<div v-if="peppolConfig.baswareConfig" style="margin-top: 5px; margin-bottom: 5px">
						<p class="font-bold">Peppol type:</p>
						<DropdownSelectAll
							:data="peppolTypeIdentifiers"
							:key="peppolConfig.baswareConfig.peppolMessageTypes"
							:selected="peppolConfig.baswareConfig.peppolMessageTypes"
							:disabled="true"
							:no-select-all="true"
						/>
					</div>
				</div>
			</div>
			<!-- Internal FTP-->
			<div class=" one-line-input">
				<div class="one-line">
					<p class="font-bold mr-2">Internal FTP:</p>
					<ToggleSlide
						class="w-min"
						:bool="peppolConfig.internalFTPConfig !== undefined"
						:disabled=true
						:renew-bool="true"
					/>
				</div>
				<div class="ml-6 w-full" v-if="peppolConfig.internalFTPConfig">
					<div v-if="peppolConfig.internalFTPConfig" style="margin-top: 5px; margin-bottom: 5px">
						<p class="font-bold">Peppol type:</p>
						<DropdownSelectAll
							:data="peppolTypeIdentifiers"
							:key="peppolConfig.internalFTPConfig.peppolMessageTypes"
							:selected="peppolConfig.internalFTPConfig.peppolMessageTypes"
							:disabled="true" :noSelectAll="true" :renew-bool="true"
						/>
					</div>
					<Field
						type="text"
						v-model="peppolConfig.internalFTPConfig.internalFTP"
						:disabled="true"
						:renew-bool="true"
					/>
				</div>
			</div>
			<!--webhook-->
			<div class="one-line-input ">
				<div class="one-line">
					<p class="font-bold mr-2">Webhook:</p>
					<ToggleSlide
						:bool="peppolConfig.webhookConfig !== undefined"
						:disabled="true"
						:renew-bool="true"
					/>
				</div>
				<div class="ml-6 w-full" v-if="peppolConfig.webhookConfig">
					<div v-if="peppolConfig.webhookConfig" style="margin-top: 5px; margin-bottom: 5px">
						<p class="font-bold">Peppol type:</p>
						<DropdownSelectAll
							:data="peppolTypeIdentifiers"
							:key="peppolConfig.webhookConfig.peppolMessageTypes"
							:selected="peppolConfig.webhookConfig.peppolMessageTypes"
							:disabled="true" :noSelectAll="true"
						/>
					</div>
					<Field
						type="text"
						v-model="peppolConfig.webhookConfig.webhook"
						:disabled=true
						:copy-suffix="true"
						:is-copied="copyWebhook"
						@click="copy('webHook')"
					/>
					<div class="one-line">
						<p class="font-bold mr-2">Use put request:</p>
						<ToggleSlide
							class="w-min"
							:bool="peppolConfig.webhookConfig.usePutRequest"
							:disabled=true
							:renew-bool="true"
						/>
					</div>
				</div>
			</div>
			<!--API Key-->
			<div class="one-line-input ">
				<div class="one-line">
					<p class="font-bold mr-2">API: </p>
					<ToggleSlide
						class="w-min"
						:bool="peppolConfig.apiKey !== undefined"
						:disabled="true"
						:renew-bool="true"
					/>
				</div>

				<div class="ml-6 w-full" v-if="peppolConfig.apiKey">
					<Field
						type="text"
						:modelValue="peppolConfig.apiKey"
						:disabled=true
						:copySuffix="true"
						:is-copied="copyApiKey"
						@copy="copy('apiKey')"
					/>
				</div>

			</div>
			<!--Email-->
			<div class="one-line-input">
				<div class="one-line">
					<p class="font-bold mr-2">Email:</p>
					<ToggleSlide
						class="w-min"
						:bool="peppolConfig.emailConfig !== undefined"
						:disabled="true"
						:renew-bool="true"
					/>
				</div>

				<div class="ml-5 w-full" v-if="peppolConfig.emailConfig">
					<div>
						<div v-if="peppolConfig.emailConfig" style="margin-top: 5px; margin-bottom: 5px">
							<p class="font-bold">Peppol type:</p>
							<DropdownSelectAll
								:data="peppolTypeIdentifiers"
								:key="peppolConfig.emailConfig.peppolMessageTypes"
								:selected="peppolConfig.emailConfig.peppolMessageTypes"
								:disabled="true" :noSelectAll="true"
							/>
						</div>
						<div>
							<!--Negative IMR only-->
							<div class="one-line">
								<p class="font-bold mr-2">Negative IMR only:</p>
								<ToggleSlide
									class="w-min"
									:bool="peppolConfig.emailConfig.negativeIMROnly"
									:disabled=true
									:renew-bool="true"
								/>
							</div>
							<p class="font-bold form-label mr-2">Mails:</p>
							<button class="btn bg-theme-1 py-0 text-white shadow-md ml-2" :disabled=true>Add</button>

							<div
								v-for="(item, index) of Object.keys(peppolConfig.emailConfig.emails)"
								:key="'editAdminEmail' + index"
								class="flex flex-row input-group w-full mb-2 pl-6"
							>
								<div class="input-group-text">
									{{ peppolConfig.emailConfig.emails[item] }}
								</div>
								<input
									type="text"
									:name="item"
									class="form-control"
									:value="item"
									:disabled=true
								/>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

import Field from "../Field.vue"
import Dropdown from "../Dropdown.vue"
import ToggleSlide from "../ToggleSlide.vue"
import DropdownSelectAll from '../DropdownSelectAll.vue'
export default {
	name: "ViewPeppolDocuments",
	components: {DropdownSelectAll, Field, Dropdown, ToggleSlide},
	props: {
		isFor: {
			required: true,
			type: String
		}
	},
	beforeMount() {
		this.isExternalFTPChecked = 'column'
	},
	data() {
		return {
			copyApiKey: false,
			copyWebhook: false,
			togglePeppolConfig: false,
			ftpTypeOptions: [
				{id: 0, name: "FTPS"},
				{id: 1, name: "SFTP"},
				{id: 2, name: "FTP"},
			],
			isExternalFTPChecked: 'column',
			peppolTypeIdentifiers: this.$store.state.peppolMessageType,
		}

	},

	computed: {
		activeData() {
			let activeData = {}
			if (this.isFor === "supplierGroup") {
				activeData = this.$store.state.activeSG
			}
			if (this.isFor === "legalEntity") {
				activeData = this.$store.state.activeLE
			}

			return activeData

		},
		displayPeppolConfig() {
			const peppolConfiguration = this.peppolConfig
			return !(peppolConfiguration.internalFTPConfig === undefined
				&& peppolConfiguration.externalFTPConfig === undefined
				&& peppolConfiguration.webhookConfig === undefined
				&& peppolConfiguration.baswareConfig === undefined
				&& peppolConfiguration.emailConfig === undefined
				&& peppolConfiguration.apiKey === undefined);
		},

		peppolConfig() {
			let peppolConfig = null
			if (this.isFor === "supplierGroup" && this.$store.state.activeSG.peppolConfiguration) {
				this.setDisplayDirection(this.$store.state.activeSG.peppolConfiguration)
				peppolConfig = this.$store.state.activeSG.peppolConfiguration
			}
			if (this.isFor === "legalEntity" && this.$store.state.activeLE?.peppolConfiguration) {
				this.setDisplayDirection(this.$store.state.activeLE.peppolConfiguration)
				peppolConfig = this.$store.state.activeLE.peppolConfiguration
			}

			if (peppolConfig === null) peppolConfig = {}
			return peppolConfig
		}
	},

	methods: {
		setDisplayDirection(peppolConfiguration) {
			if (peppolConfiguration.externalFTPConfig !== undefined) this.isExternalFTPChecked = 'row'
			else this.isExternalFTPChecked = 'column'
		},
		copy(field) {
			if (field === "apiKey") {
				navigator.clipboard.writeText(this.peppolConfig.apiKey);
				this.copyApiKey = true
			} else if (field === 'webHook') {
				navigator.clipboard.writeText(this.peppolConfig.webhookConfig.webhook)
				this.copyWebhook = true
			}
			setTimeout(() => {
				this.copyApiKey = false
				this.copyWebhook = false
			}, 750);
		},
	}
}
</script>

<style scoped>
.one-line {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.one-line-input {
	margin-bottom: 8px;
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: start;
}
</style>
